//https://blog.logrocket.com/vue-middleware-pipelines/
import axios from "axios";
async function guest ({ next, store ,to }){
  
  console.log('Update_Current_Url' , to.name);
    store.commit('Update_Current_Url' , to.name);
    if(store.state.currentUserData.permission != '' && store.state.currentUserData.permission != null){
        var data = store.state.currentUserData
        if(data.permission.status == 0){
          await axios.post(store.state.baseUrl +'getUserPermission',{'user_id': store.state.currentUserData.permission.user_id ,'invited_team_id': store.state.currentUserData.permission.invited_team_id },{"headers":{ Authorization: 'Bearer '+store.state.currentUserData.token , TimeZone : store.state.userTimeZone }})
          .then((response) => {
              if(response.data.status == true){
                if(data.permission.updated_at == response.data.result.updated_at){
                  data.permission = response.data.result;
                  store.commit('setcurrentUserData', data);
                  CheckAuthUser(to,next,store);
                }else{
                  login(store,next);
                }
              }else{
                login(store,next);
              }
          })
          .catch((error) => {
            error
            login(store,next);
          })
        }else{
          login(store,next);
        }
    }else{
      CheckAuthUser(to,next,store);
    }
}
// this fun is for to check user are allowed to current cul or not
function CheckAuthUser(to,next,store){
    var loadPath        = to.path.split("/")[1];
    var URL2            = to.path.split("/")[2];
    var vuex            = store.state;
    // var vuex            = JSON.parse(localStorage.getItem('vuex'));
    const requiresAuth  = to.matched.some((record) => record.meta.requiresAuth);
    const isUserLogin   = store.state.currentUserData.token;
    // const isUserLogin   = localStorage.getItem("token");

    // this is for to check user have permission to some routes
    if(requiresAuth && isUserLogin && loadPath == 'client'){
      if(URL2=='dashboard'){
        checkRoutePermission(store,next,'my-jobs','client/search');
      }else if(URL2 == 'job-posting'){
        checkRoutePermission(store,next,'all-job-post','client/search');
      }else if(URL2 == 'post-job'){
        checkRoutePermission(store,next,'post-job','client/search');
      }else if(URL2 == 'all-contracts'){
        checkRoutePermission(store,next,'all-contracts','client/search');
      }else if(URL2 == 'talent'){
        checkRoutePermission(store,next,'talent','client/search');
      }else if(URL2 == 'hire-freelancer' || URL2 == 'hire-details'){
        checkRoutePermission(store,next,'hire-btn','client/search');
      }else if(URL2 == 'saved-freelancer'){
        checkRoutePermission(store,next,'client-saved','client/search');
      }
    }else if(requiresAuth && isUserLogin && loadPath == 'agency'){
      if(URL2=='all-contracts'){
        checkRoutePermission(store,next,'agency-all-Contracts','agency/search');
      }else if(URL2=='submit-proposal'){
        checkRoutePermission(store,next,'apply-job','agency/search');
      }else if(URL2=='my-proposals'){
        checkRoutePermission(store,next,'find-job','agency/search');
      }else if(URL2=='roster'){
        checkRoutePermission(store,next,'find-job','agency/search');
      }
    }else if(requiresAuth && isUserLogin && (loadPath == 'saved-jobs' || loadPath == 'agency-profile')){
      checkRoutePermission(store,next,'find-job','agency/search');
    }

    // if(vuex.currentUserData.updateProfile_afterVerification == 0 && store.accountType == 'freelancer'){
    if(loadPath == 'public-profile' || loadPath == 'agency-public-profile'){
      next();
    }else{
      if (to.name == "NewsFeed" || to.name == "ExpireLink" || to.name=="ResetPassword" || to.name=="Login") {
        store.commit("isNewsfeed", false);
      }
      if(loadPath == 'settings' && URL2 == 'addfreelancer' ){
        loadPath        = 'settings/addfreelancer';
      }
      if (requiresAuth && !isUserLogin) {
        next("/login");
      }else if(URL2=='submit-proposal' && vuex.accountType == loadPath && vuex.accountType != 'client'){
        next();
      } else if (requiresAuth && isUserLogin) {
        // var accountType = localStorage.getItem("accountType"); 
        if(vuex.accountType == loadPath && vuex.accountType != 'freelancer'){
          next();
        }else if(vuex.accountType == loadPath && vuex.accountType == 'freelancer' && vuex.currentUserData.updateProfile_afterVerification == 1){
          next();
        }else{
          if(loadPath =='add-client-account' || loadPath == 'settings' || loadPath == 'saved-jobs' || loadPath == 'news-feed' 
            || loadPath == 'public-profile' || loadPath =='my-profile' || loadPath == 'add-wire-transfer' || loadPath == 'update-wire-transfer' || loadPath == 'usa-bank' || loadPath == 'update-usa-bank'
            || loadPath =='add-freelancer-account' || loadPath == 'agency-public-profile' || loadPath == 'chat' || loadPath == 'update-profile'){
              if(vuex.accountType == 'freelancer' && vuex.currentUserData.updateProfile_afterVerification == 0 && loadPath != 'update-profile'){
                // window.location.href = "update-profile";
                next('update-profile');

              }else if(vuex.accountType == 'freelancer' && vuex.currentUserData.updateProfile_afterVerification == 1 && loadPath == 'update-profile'){
                // window.location.href = "/freelancer";
                next('/freelancer');
              }else{
                next();
              }
          }else if(vuex.accountType == 'agency' && loadPath == 'agency-profile' ){
            next();
          }else if(vuex.accountType == 'freelancer' && loadPath=='add-agency-account'){
            next();
          }else if(vuex.accountType == 'client' && loadPath=='settings/addfreelancer'){
            next();
          }else{ 
            // if (window.history.state == null) {
              if(vuex.accountType == 'freelancer' && vuex.currentUserData.updateProfile_afterVerification == 0){
                next('update-profile');
              }else if(vuex.accountType=='agency'){
                window.location.href = "/agency/search";
                next('/agency/search');
              }else if(vuex.accountType =='freelancer'){
                window.location.href = "/freelancer";
                next('/freelancer');
              }else if(vuex.accountType=='client'){
                window.location.href = "/client/dashboard";
                next('/client/dashboard');
              }
            // }else{
            //   window.history.back()
            // }
          }
        }
      } else {
        if(loadPath == 'login' && isUserLogin){
          dashboardRedirect(store,next)
        }else if(loadPath == 'sign-up' && isUserLogin){
          dashboardRedirect(store,next)
        }else if(loadPath == 'forgot-password' && isUserLogin){
          dashboardRedirect(store,next)
        }else if(loadPath == 'reset-password' && isUserLogin){
          dashboardRedirect(store,next)
        }else{
          next();
        }
      }
    }
}
// this fun is for to destroy current session and redirect to login
function login(store,next){
    store.dispatch("currentUserData", '');
    store.dispatch("accountType", '');
    store.state.ExperienceLevelData = [];
    store.state.LanguageData = [];
    store.state.certificationList = [];
    store.state.currentUserOwnerData = [];
    store.state.listAccount = {
        company: '',
        qp_user: '',
        user: '',
    };
    store.state.loginUserUuid = '';
    store.state.profileImg = '';
    store.state.swiftKey = '';
    store.state.talentData = [];
    localStorage.removeItem("token");
    localStorage.removeItem("accountType");
    localStorage.removeItem("loginUserData");
    localStorage.removeItem("loginUserUuid");
    // vueToastr.s("Permission Changed Please Login.");
    // return next({
    //     name: 'Login'
    // })
    return  next("/login");
}
// this fun is for to check user have permission for this section
function checkRoutePermission(store,next,type,route=null){
  if(store.state.currentUserData.permission=='' ||store.state.currentUserData.permission== undefined){
      return true;
  }
  if(type=='my-jobs'){
    if(store.state.currentUserData.permission.hiring == 1){
      next(route); 
    }
  }else if(type=='all-job-post'){
    if(store.state.currentUserData.permission.hiring == 1){
      next(route); 
    }
  }else if(type=='post-job'){
    if(store.state.currentUserData.permission.hiring == 1){
      next(route); 
    }   
   }else if(type == 'all-contracts'){
    if(store.state.currentUserData.permission.hiring < 3){
      next(route); 
    } 
   }else if(type == 'talent'){
    if(store.state.currentUserData.permission.hiring == 1){
      next(route); 
    } 
   }else if(type == 'hire-btn'){
    if(store.state.currentUserData.permission.hiring < 3){
      next(route); 
    } 
   }else if(type == 'client-saved'){
    if(store.state.currentUserData.permission.admin < 3){
      next(route); 
    } 
   }else if(type == 'agency-all-Contracts'){
    if(store.state.currentUserData.permission.business == 1){
      next(route); 
    } 
   }else if(type == 'find-job'){
    if(store.state.currentUserData.permission.business == 1){
      next(route); 
    } 
  }else if(type == 'apply-job'){
    if(store.state.currentUserData.permission.business == 1){
      next(route); 
    } 
   }
}
//this fun is for to redirect all type of user to dashboard
function dashboardRedirect(store,next){
  if(store.state.accountType == 'freelancer'){
    next("/freelancer");
  }else if(store.state.accountType == 'agency'){
    next("/agency/search");
  }else if(store.state.accountType == 'client'){
    next("/client/dashboard");
  }
}
export default  guest