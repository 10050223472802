import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import { isEqual } from 'lodash';
Vue.use(Vuex)

const global = {
  namespaced: true,

  state: () => ({
    refreshNewsFeedData   : Function,
    countryList           : [],
    cityList              : [],
    stateList             : [],
    qrRoleMasterList      : [],
    timezone              : [],
    roomData              : [],
    mChatData             : [],
    requestData           : {
      title                   : '',
      sort                    : '',
      status                  : 1,
      userType                : '',
      user_id                 : ''
    },
    invitationRequestData : {
      member_permission_id    : '',
      user_id                 : '',
      title                   : '',
      sort                    : '',
    },
    mRequestData          : {
      chat_room_id            : '',
      start_limit             : 0,
      end_limit               : 10,
      cycle                   : '',
      lastId                  : '',
      firstId                 : '',
    },
    scrollCycle           : '',
    chatLoader            : false,
    chatroomResponse      : true,
  }),

  getters: {
    refreshNewsFeed: (state) => state.refreshNewsFeedData,
    getCountryList: (state) => state.countryList,
    getCityList: (state) => state.cityList,
    getStateList: (state) => state.stateList,
    getQrRoleMasterList: (state) => state.qrRoleMasterList,
    getTimeZone: (state) => state.timezone,
    getRoomData: (state) => state.roomData,
    getmChatData: (state) => state.mChatData,
    getmessageCycle: (state) => state.scrollCycle,
    getchatLoader: (state) => state.chatLoader,
    getchatroomResponse: (state) => state.chatroomResponse,
  },

  mutations: {
    REFRESH_NEWS_FEED(state, fun) {
      state.refreshNewsFeedData = fun
    },
    GET_COUNTRY_LIST(state, data) {
      state.countryList = data
    },
    GET_CITY_LIST(state, data) {
      state.cityList = data
    },
    GET_STATE_LIST(state, data) {
      state.cityList = data
    },
    GET_QR_ROLE_MASTER_LIST(state, data) {
      state.qrRoleMasterList = data
    },
    GET_TIME_ZONE(state, data) {
      state.timezone = data
    },
    Update_Request_Data(state, data) {
      state.requestData = data
    },
    Update_Invitation_Request_Data(state, data) {
      state.invitationRequestData = data
    },
    Update_Room_Data(state, data) {
      if(!isEqual(state.roomData, data)){
        state.roomData = data;
        return true;
      }else{
        return false;
      }
    },
    Update_Message_Request_Data(state, data) {
      state.mRequestData = data
    },
    Update_scrollCycle_Data(state, data) {
      state.scrollCycle = data
    },
    Update_Message_Chat_Data(state, res){
      if (res.count != '' && res.count > 0) {
        state.mRequestData.start_limit += res.count;
        if(state.mChatData.length == 0){
          state.mChatData = res.response
          if(res.cycle == 'first'){
            setTimeout(() => {
              state.chatLoader = false;
            }, 4000);
          }
        }else{
          res.response.map((data,index)=>{
            if(index == 0){
              var parentIndex
              if(res.cycle == 'up'){
                parentIndex = 0;
              }else{
                parentIndex = state.mChatData.length-1;
              }
              
              if(data.key == state.mChatData[parentIndex].key && res.cycle == 'up'){
                data.value.map((appendData,ind) => {
                  if(ind == 0){
                    // var childIndex = state.mChatData[parentIndex].value.length-1;
                    var childIndex = 0;
                    // var timediff = that.timeDiffrenceInminutes(state.mChatData[0].value[childIndex].key.created_at,appendData.key.created_at);
                    var startTime = new Date(state.mChatData[parentIndex].value[childIndex].key.created_at); 
                    var endTime = new Date(appendData.key.created_at);
                    var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
                    var timediff = Math.round(difference / 60000);
                    if(state.mChatData[parentIndex].value[childIndex].key.sended_by == appendData.key.sended_by && timediff < 10){
                      appendData.value.map(pushdata => {
                        var childChildIndex = 0;
                        // var childChildIndex = state.mChatData[parentIndex].value[childIndex].value.length-1;
                        // if(pushdata.type == state.mChatData[0].value[0].value[0].type){
                        if(pushdata.type == state.mChatData[parentIndex].value[childIndex].value[childChildIndex].type && pushdata.type != 1){
                          // if(pushdata.length > 0){
                            pushdata.data.map(childChildData => {
                              state.mChatData[parentIndex].value[childIndex].value[childChildIndex].data.unshift(childChildData);
                            })
                          // }else{
                          //   state.mChatData[0].value[0].value[0].data.unshift(pushdata);
                          // }
                        }else{
                          state.mChatData[parentIndex].value[childIndex].value.unshift(pushdata);
                        }
                      });
                    }else{
                      appendData.value.reverse();
                      state.mChatData[parentIndex].value.unshift(appendData);
                    }
                  }else{
                    appendData.value.reverse();
                    state.mChatData[parentIndex].value.unshift(appendData);
                  }
                });
              }else if(data.key != state.mChatData[parentIndex].key && res.cycle == 'up'){
                state.mChatData.unshift(data);
              }else if(data.key == state.mChatData[parentIndex].key && res.cycle != 'up'){
                data.value.map(appendData => {
                  var childIndex = state.mChatData[parentIndex].value.length-1;
                  var startTime = new Date(state.mChatData[parentIndex].value[childIndex].key.created_at); 
                  var endTime = new Date(appendData.key.created_at);
                  var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
                  var timediff = Math.round(difference / 60000);
                  // if(state.mChatData[parentIndex].value[childIndex].key.sended_by == appendData.key.sended_by){
                  if(state.mChatData[parentIndex].value[childIndex].key.sended_by == appendData.key.sended_by && timediff < 10){
                    appendData.value.map(pushdata => {
                      var childChildIndex = state.mChatData[parentIndex].value[childIndex].value.length-1;
                      if(pushdata.type == state.mChatData[parentIndex].value[childIndex].value[childChildIndex].type && pushdata.type != 1){
                        pushdata.data.map(childChildData => {
                          state.mChatData[parentIndex].value[childIndex].value[childChildIndex].data.push(childChildData);
                        })
                      }else{
                        state.mChatData[parentIndex].value[childIndex].value.push(pushdata);
                      }
                    });
                  }else{
                    if(res.cycle=='up'){
                      state.mChatData[parentIndex].value.unshift(appendData);
                    }else{
                      state.mChatData[parentIndex].value.push(appendData);
                    }    
                  }
                });
              }else {
                state.mChatData.push(data);
              }
            }else{
              if(res.cycle=='up'){
                state.mChatData.unshift(data);
              }else{
                state.mChatData.push(data);
              }
            }
          }); 
        }

        if(res.cycle == 'first'){
          state.mRequestData.firstId = res.first_id;
          state.mRequestData.lastId = res.last_id;
          state.mRequestData.cycle  = 'recurring';
          state.scrollCycle = res.cycle;
        }else if(res.cycle == 'up'){
          // console.log('res.cycle',res.cycle)
          // state.scrollCycle = '';
          state.mRequestData.firstId = res.first_id;
          state.mRequestData.cycle  = 'recurring';
          state.scrollCycle = res.cycle;
        }else if(res.cycle == 'recurring'){
          state.mRequestData.lastId = res.last_id;
          if(state.scrollCycle != res.cycle){
            state.scrollCycle = res.cycle;
          }
        }
      }
      else{
        if(state.mRequestData.cycle == 'up'){
          state.mRequestData.cycle = 'recurring';
        }
      }
      return true;
    },
    Empty_Message_Request_Data(state, data) {
      state.mChatData = data
    },
    Update_Chat_Loader_Status(state, data) {
      state.chatLoader = data
    },
    Update_Chat_Room_Response(state, data) {
      state.chatroomResponse = data
    },
  },
  actions: {
    UpdateMessageCycleData({commit},value){
      commit('Update_scrollCycle_Data',value)
    },
    // this fun is for to update chat Room response
    UpdateChatRoomResponse({commit} ,value) {
      // console.log('Update_Chat_Room_Response',commit, value);
      commit('Update_Chat_Room_Response', value);
    },
    // this fun is for to update chat Room data request
    UpdateChatLoaderStatus({ commit },{ params}) {
      commit('Update_Chat_Loader_Status', params);
    },
    // this fun is for to update chat Room data request
    EmptyRoomData({ commit },{params}) {
      commit('Update_Room_Data', params);
    },
    // this fun is for to update chat Room data request
    EmptyMessageData({ commit },{params}) {
      // console.log('Empty_Message_Request_Data', params);
      commit('Empty_Message_Request_Data', params);
    },
    // this fun is for to update chat Room data request
    UpdateMessageRequestData({ commit }, {params}) {
      commit('Update_Message_Request_Data', params);
    },
    // this fun is for to get message data
    async fetchChatData({commit}){
      try {
        let response1;
        var that = this;
        var request = that.state.global.mRequestData;
        // console.log('request',request.cycle);
        if((request.cycle == 'first' || request.start_limit == 0) && that.state.currentUrl != 'messageSideBar'){
          request.cycle = 'first';
          commit('Empty_Message_Request_Data', []);
          commit('Update_Chat_Loader_Status',true);
          commit('Update_scrollCycle_Data','');
        }else if(request.cycle == 'up'){
          commit('Update_scrollCycle_Data','');
        }
        response1 = await axios.post(that.state.baseUrl+'getMessage', request,{"headers":{ Authorization: 'Bearer '+that.state.currentUserData.token, TimeZone : that.state.userTimeZone }});
        if(response1.data.status == true){
          var res = response1.data.result;
          if(res.cycle == 'first' && res.response.length == 0){
            commit('Update_Chat_Loader_Status',false);
            commit('Update_scrollCycle_Data',res.cycle);
          }else if(res.cycle == 'up' && res.response.length > 0){
            commit('Update_scrollCycle_Data','');
            commit("Update_Message_Chat_Data",res);
          }else if(res.response.length > 0){
            commit("Update_Message_Chat_Data",res);
          }
          return true;
        }else{
          commit('Update_Chat_Loader_Status',false);
          return true;
        }
      } catch (error) {
        if(error=='Error: Request failed with status code 401'){
            this.state.currentUserData = '';
            localStorage.removeItem("token"); 
            localStorage.removeItem("accountType"); 
            localStorage.removeItem("loginUserData"); 
            window.location.href = "/login";
            return false;

        }else if(error == 'Error: Network Error'){
          return true;
        }
      }
    },
    // this fun is for to update chat Room data request
    UpdateRequestRoomData({ commit }, { params }) {
      commit('Update_Request_Data', params)
      return true;
    },
    // this fun is for to update invitation chat Room data request
    UpdateInvitationRequestRoomData({ commit }, { params }) {
      commit('Update_Invitation_Request_Data', params)
      return true;
    },
    async fetchChatRoomData({commit},{requestUrl}){
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      try {
        let res;
        if (requestUrl == 'getchatroom') { 
          res = await axios.post(this.state.baseUrl + requestUrl, this.state.global.requestData,{"headers":this.headToken});
        } else {
          res = await axios.post(this.state.baseUrl + requestUrl, this.state.global.invitationRequestData,{"headers":this.headToken});
        }

        if(res.data.status == true){
          commit("Update_Room_Data", res.data.result);
        }else{
          commit("Update_Room_Data", []);
        }
        return true;

      } catch (error) {
        if(error=='Error: Request failed with status code 401'){
            this.state.currentUserData = '';
            localStorage.removeItem("token"); 
            localStorage.removeItem("accountType"); 
            localStorage.removeItem("loginUserData"); 
            window.location.href = "/login";
        }else if(error == 'Error: Network Error'){
          return true;
        }
      }
    },
    refreshNewsFeedFunc({ commit }, { refresh }) {
      commit('REFRESH_NEWS_FEED', refresh)
    },
    fetchCountryList({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: this.state.baseUrl + 'common/getCountryList',
        })
          .then((response) => {
            commit('GET_COUNTRY_LIST', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchCityList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.state.baseUrl}common/getCityList`, payload)
          .then((response) => {
            commit('GET_CITY_LIST', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchStateList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${this.state.baseUrl}common/getStateList`,
          data: {
            payload, // This is the body part
          },
        })
          .then((response) => {
            commit('GET_STATE_LIST', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchQpRoleMasterList({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: this.state.baseUrl + 'common/getQpRoleMasterList',
        })
          .then((response) => {
            commit('GET_QR_ROLE_MASTER_LIST', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchTimeZone({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: this.state.baseUrl + 'common/getTimeZoneList',
        })
          .then((response) => {
            commit('GET_TIME_ZONE', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
export default global
