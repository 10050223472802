<template>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active">
            <div class="container-fluid no-gutter app mart-81 mb-100 mb-0">
                <div class="row app-one m-0">
                    <div class="col-sm-2 side p-0">
                        <div class="side-one">
                            <div class="row heading searchbox">
                                <div class="col-sm-12 searchBox-inner">
                                    <div class="d-flex align-items-center">
                                        <div class="form-group has-feedback width-95">
                                            <input id="searchText" v-model="search" type="text" class="form-control" @keyup="getChatlist()" placeholder="Search">
                                        </div>
                                        <div class="ml-auto width-5 bg-white">
                                            <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret>
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-v text-black mr-3" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black" @click="checkAutoresponse()" v-b-modal.out-of-office> Out of office</a>
                                                </b-dropdown-item>

                                                <b-modal id="out-of-office" size="lg" title="Out-of-Office Responder" centered hide-footer no-close-on-backdrop>
                                                    <form @submit.prevent="submitAutoResponse">
                                                    <div>
                                                        <div class="d-flex align-items-center mb-3">
                                                            <label class="switch">
                                                                <input type="checkbox" v-model="autoResponde.active" @click="toggleCheckbox">
                                                                <div class="slider round"></div>
                                                            </label>
                                                            <p class="ml-2 mb-0">Send automatic replies to incoming messages</p>
                                                        </div>
                                                        <span v-if="autoResponde.active">
                                                            <p>Your time zone is currently set to {{autoResponde.timezone}}. <a href="javascript:void(0);" class="text-theme" @click="changeTimeZone = true;">Change</a></p>
                                                            <p v-if="changeTimeZone">Select timezone for out of office response.</p>
                                                            <b-row v-if="changeTimeZone">
                                                                <b-col xl="6" lg="6" sm="12">
                                                                    <b-form-group>
                                                                        <b-form-select
                                                                        v-model="autoResponde.timezone_id"
                                                                        :options="timezoneList"
                                                                        class="form-control"
                                                                        value-field="timezone_id"
                                                                        text-field="name"
                                                                        :error-messages="timeZoneError"
                                                                        @input="$v.autoResponde.timezone_id.$touch()"
                                                                        @blur="$v.autoResponde.timezone_id.$touch()"
                                                                        ></b-form-select>
                                                                        <div class="error" v-if="timeZoneError[0]">{{timeZoneError[0]}}</div>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col xl="6" lg="6" sm="12">
                                                                    <b-form-group>
                                                                        <label class="fz-16">First day</label>
                                                                        <b-form-datepicker  v-model="autoResponde.from_date" :value="autoResponde.from_date" :min="currentDate" @input="autoResponde.to_date = autoResponde.from_date" required class="mb-2"></b-form-datepicker>
                                                                        <div class="error" v-if="fromDateError[0]">{{fromDateError[0]}}</div>
                                                                        <div class="error" v-if="autoResponde.from_date < currentDate">
                                                                            <div class="up-icon"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 14 14" role="img"><path fill-rule="evenodd" d="M8 7.927V9H6V2h2v5.927zM7 12a1 1 0 110-2 1 1 0 010 2zM7 0C3.15 0 0 3.15 0 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7z"></path></svg></div>
                                                                            Date should be greater than {{currentDate}}
                                                                        </div>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col xl="6" lg="6" sm="12">
                                                                    <b-form-group>
                                                                        <label class="fz-16">Last day</label>
                                                                        <b-form-datepicker  v-model="autoResponde.to_date" :value="autoResponde.to_date" :min="new Date(autoResponde.from_date).toISOString().substr(0, 10)"  required class="mb-2"></b-form-datepicker>
                                                                        <div class="error" v-if="toDateError[0]">{{toDateError[0]}}</div>
                                                                        <div class="error" v-if="autoResponde.to_date < currentDate">
                                                                            <div class="up-icon"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 14 14" role="img"><path fill-rule="evenodd" d="M8 7.927V9H6V2h2v5.927zM7 12a1 1 0 110-2 1 1 0 010 2zM7 0C3.15 0 0 3.15 0 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7z"></path></svg></div>
                                                                            Date should be greater than {{currentDate}}
                                                                        </div>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col xl="12" lg="12" sm="12">
                                                                    <b-form-group>
                                                                        <label class="fz-16">Your message</label>
                                                                        <b-form-textarea
                                                                        id="textarea"
                                                                        v-model="autoResponde.message"
                                                                        placeholder="Your message here..."
                                                                        rows="3"
                                                                        max-rows="6"
                                                                        ></b-form-textarea>
                                                                        <div class="error" v-if="messageError[0]">{{messageError[0]}}</div>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <p class="mt-3" v-if="$store.state.accountType != 'client'"><strong>Note:</strong> This setting does not affect your <a href="javascript:void(0);" class="text-theme">freelancer availability</a>. You can adjust your availability on the <a href="javascript:void(0);" class="text-theme">Find Work</a> page</p>
                                                            <p><b>You will appear as Out of Office across all your companies on Qapin.</b></p>
                                                        </span>
                                                    </div>
                                                    <template>
                                                        <div class="w-100 text-right">
                                                            <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('out-of-office');$v.$reset()">
                                                                Cancel
                                                            </a>
                                                            <b-button size="lg" class="btn btn-theme" type="submit"
                                                            :disabled="(autoResponde.from_date > currentDate ) ? true : false "
                                                            >
                                                                Save
                                                            </b-button>
                                                        </div>
                                            
                                                    </template>
                                                    </form>
                                                </b-modal>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <label class="text-black fz-16"> Filter rooms by</label>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'ASC';getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='ASC'"></i> All rooms</a>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'DESC'; getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='DESC'"></i> All rooms, recent first</a>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'unread'; getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='unread'"></i> Unread only</a>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'interview'; getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='interview'"></i> Interview only</a>
                                                </b-dropdown-item> 
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'contract'; getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='contract'"></i> Contract only</a>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="contract-options p-3 bg-white border-bottom" v-if="sort!='' && sort!='ASC'"> -->
                            <div class="contract-options p-3 bg-white border-bottom" v-if="sort!='' && sort!='DESC'">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <i class="fa fa-sliders mr-2" aria-hidden="true"></i>
                                        <!-- <span class="text-muted">{{(sort=='DESC')?'All rooms, recent first':(sort=='unread')?'Unread only':(sort=='interview')?'Interview only':(sort=='contract')?'Contract only':''}}</span> -->
                                        <span class="text-muted">{{(sort=='ASC')?'All rooms':(sort=='unread')?'Unread only':(sort=='interview')?'Interview only':(sort=='contract')?'Contract only':''}}</span>
                                    </div>
                                    <div class="ml-auto">
                                        <a href="javascript:void(0);" @click="sort ='DESC'; getChatlist()">
                                            <i class="fa fa-times text-black" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-name-wrap" v-if="roomlist.length > 0 ">
                                <!-- <template v-for="(data,index) in roomlist" > -->
                                    <div class="row sideBar-body" v-for="(data,index) in roomlist" :key="index" :class="(data.chat_room_id == chatroomdata.chat_room_id)?'unread':''" @click="(data.chat_room_id == chatroomdata.chat_room_id || !chatroomResponse) ? '': getselectedusermessage(data)">
                                        <a href="javascript:void(0)" @click="(data.chat_room_id == chatroomdata.chat_room_id || !chatroomResponse) ? '': getselectedusermessage(data)" v-if="$store.state.accountType == 'client'" class="d-flex align-items-start p-0" :class="(data.chat_room_id == chatroomdata.chat_room_id && !chatroomResponse) ? 'cursor-progress' : (data.chat_room_id != chatroomdata.chat_room_id && !chatroomResponse)?'cursor-block':'ali'">
                                            <div class="sideBar-avatar">
                                                <div class="avatar-icon">
                                                    <img :src="data.user_image">
                                                    <span class="status-icon" :class="(checkRoomUserStatus(data) == true) ? 'bg-success' : ''"></span>
                                                </div>
                                            </div>
                                            <div class="sideBar-main">
                                                <div class="sideBar-name">
                                                    <h5 class="name-meta mb-0 fz-16 p-0">{{shortName(data.user_first_name,data.user_last_name)}}</h5>
                                                    <h6 class="text-black mb-0 fz-14 job-title">{{data.job_title}} </h6>
                                                    <!-- <div class="absolute w-4 h-4 text-xs text-center text-white rounded-full bg-primary-500 notification-status">0</div> -->
                                                    <span class="text-muted mb-0 fz-14" v-html="getHtmlContent(data.message)"></span>
                                                </div>
                                                <div class="sideBar-time">
                                                    <span class="time-meta pull-right">{{data.time}}</span>
                                                    <div class="absolute w-4 h-4 text-xs text-center text-white rounded-full bg-primary-500 notification-status" v-if="data.unreadMessage > 0">{{data.unreadMessage}}</div>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0)" @click="(data.chat_room_id == chatroomdata.chat_room_id || !chatroomResponse) ? '': getselectedusermessage(data)" v-else-if="data.status == 1" class="d-flex align-items-start p-0" :class="(data.chat_room_id == chatroomdata.chat_room_id && !chatroomResponse)?'cursor-progress':(data.chat_room_id != chatroomdata.chat_room_id && !chatroomResponse)?'cursor-block':'ali'"  >
                                            <div class="sideBar-avatar">
                                                <div class="avatar-icon">
                                                    <img :src="data.client_image">
                                                    <span class="status-icon" :class="(checkRoomUserStatus(data) == true) ? 'bg-success' : ''"></span>
                                                </div>
                                            </div>
                                            <div class="sideBar-main">
                                                <div class="sideBar-name">
                                                    <h5 class="name-meta mb-0 fz-16 p-0">{{shortName(data.client_first_name,data.client_last_name)}}</h5>
                                                    <h6 class="text-black mb-0 fz-16 job-title">{{data.job_title}}</h6>
                                                    <span class="text-muted mb-0 fz-14 msg-short" v-html="getHtmlContent(data.message)"></span>
                                                </div>
                                                <div class="sideBar-time">
                                                    <span class="time-meta pull-right">{{data.time}}</span>
                                                <div class="absolute w-4 h-4 text-xs text-center text-white rounded-full bg-primary-500 notification-status" v-if="data.unreadMessage > 0">{{data.unreadMessage}}</div>

                                                </div>
                                            </div>
                                        </a>
                                        
                                    </div>
                                <!-- </template> -->
                            </div>
                            <div class="chat-name-wrap left-chat-no-data sidesection text-center" v-else>
                                <div class="row sideBar-body" >
                                    <a href="javascript:void(0)">
                                        <div class="col-sm-12 col-xl-12 sideBar-avatar">
                                            <div class="image-holder">
                                                <img src="/web/communications.png" alt="Chat" class="img-fluid">
                                            </div>
                                            <h4 class="fz-16 fw-600 mb-0 text-muted text-center" >{{roomlist.length}}No active chat room found</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-col :xl="(hidePeople == false) ? '8' : '10'" :lg="(hidePeople == false) ? '8' : '10'" sm="12" class="border-right no-gutter pt-0">
                        <div class="container-chat">
                            <b-row class="heading" v-if="$store.state.accountType=='client' && (messagelist.length > 0 ||roomlist.length > 0)">
                                <b-col xl="12" lg="12" sm="12" class="py-1">
                                    <div class="d-flex align-items-center">
                                        <div class="chat-hamburger" v-b-toggle.sidebar-left-chat @click="sidebarVisible = false">
                                            <i class="fa fa-bars px-2 py-2 border-right mr-2" aria-hidden="true"></i>
                                        </div>
                                        
                                        <div class="heading-avatar w-100 d-flex align-items-center">
                                            <div class="width-98 d-flex align-items-center">
                                                <div class="heading-name">
                                                    <a class="heading-name-meta mb-0 pt-0 fz-18 fw-600">{{ shortName(currentUser.user_first_name,currentUser.user_last_name) }}</a>
                                                    <p class="mb-0 fz-14 text-black"><span class="heading-online text-muted mt-0 fz-14">{{ currentUser.job_title }}</span></p>
                                                    <!-- <p class="ml-1 mb-0 fz-14 text-black">12:09 AM EST <span class="heading-online text-muted mt-0 fz-14" @click="$router.push({'path':'client/jobpostreview','name':'index',params:{'pid':currentUser.uniq_id}}).catch(()=>{})">{{ currentUser.job_title }}</span></p> -->
                                                </div>
                                            </div>
                                            <!-- <a class="ml-auto width-2 text-right">
                                                <i class=" text-black mr-3" :class="(hidePeople == false) ? 'fa fa-chevron-right' : 'fa fa-chevron-left'" aria-hidden="true" @click="hidePeople = (hidePeople == false) ? true : false"></i>
                                            </a> -->
                                        </div>
                                        <div class="ml-auto chat-hamburger" v-b-toggle.sidebar-right-chat>
                                            <i class="fa fa-bars px-2 py-2 border-left ml-2" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="heading" v-else-if="currentUser.status == 1 && (messagelist.length > 0 ||roomlist.length > 0)">
                                <b-col xl="12" lg="12" sm="12" class="py-1">
                                    <div class="d-flex align-items-center">
                                        <div class="chat-hamburger" @click="sidebarVisible = false" v-b-toggle.sidebar-left-chat>
                                            <i class="fa fa-bars px-2 py-2 border-right mr-2" aria-hidden="true"></i>
                                        </div>
                                        <div class="heading-avatar w-100 d-flex align-items-center">
                                            <div class="width-98 d-flex align-items-center">
                                                <div class="heading-name">
                                                    <a class="heading-name-meta mb-0 pt-0 fz-18 fw-600">{{ shortName(currentUser.client_first_name, currentUser.client_last_name) }}</a>
                                                    <p class="ml-1 mb-0 fz-14 text-black"><span class="heading-online text-muted">{{ currentUser.job_title }}</span></p>
                                                    <!-- <p class="ml-1 mb-0 fz-14 text-black">12:09 AM EST <span class="heading-online text-muted" @click="$router.push({'name':$store.state.accountType+'proposalDetails','path':'/'+$store.state.accountType+'/proposal-details/',query:{'jobDetailsWithClientData':currentUser.job_id}}).catch(()=>{})">{{ currentUser.job_title }}</span></p> -->
                                                </div>
                                            </div>
                                            <a class="ml-auto width-2 text-right">
                                                <i class=" text-black mr-3" :class="(hidePeople == false) ? 'fa fa-chevron-right' : 'fa fa-chevron-left'" aria-hidden="true" @click="hidePeople = (hidePeople == false) ? true : false"></i>
                                            </a>
                                        </div>
                                        <div class="ml-auto chat-hamburger" v-b-toggle.sidebar-right-chat>
                                            <i class="fa fa-bars px-2 py-2 border-left ml-2" aria-hidden="true"></i>
                                        </div>
                                        
                                    </div>
                                </b-col>
                            </b-row>
                            <chatBoxData ref="oprateChatbox" :roomlist="roomlist" :chatroomdata="chatroomdata" :chatroomMembers="chatroomMembers"  :pageType="'chatpage'" @refreshChatroom="getChatlist" @refreshFiles="getFiles(chatroomdata.chat_room_id)" @updateUnreadMessage="updateUnreadMessage(chatroomdata.chat_room_id)" />
                        </div>
                    </b-col>
                    <div class="col-sm-2 side p-0 chat-right-sidebar" v-if="!hidePeople">
                        <div class="side-two">
                            <template>
                                <div class="accordion p-0 border-none" role="tablist">
                                    <b-card no-body class="mb-1">
                                        <b-card-header  header-tag="header" class="p-0 border-none" role="tab">
                                            <div class="row newMessage-heading">
                                                <div class="row newMessage-main bg-grey">
                                                    <b-col xl="12" lg="12" sm="12" class="p-0 d-flex align-items-center togglePeople" v-b-toggle.accordion-1>
                                                        <h5 class="mb-0 fz-16 d-block">People</h5>
                                                        <div class="ml-auto">
                                                            <i class="fa fa-chevron-down text-black" aria-hidden="true"></i>
                                                        </div>
                                                    </b-col>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <b-collapse visible id="accordion-1" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="p-0">
                                                <div class="image-grid-wrapper" v-if="chatroomMembers.length > 0">
                                                    <div class="image-flex-wrapper" v-for="(members,index) in  chatroomMembers" :key="index">
                                                        <div class="image-wrap-inner d-flex align-items-center">
                                                            <div class="content d-flex align-items-center">
                                                                <div class="avtar-wrap">
                                                                    <img :src="members.profile_picture_path">
                                                                    <span class="status-icon" :class="(checkChatRoomMemberIsOnlineOroffline(members) == true) ? 'bg-success' : ''"></span>
                                                                </div>
                                                                <div class="content ml-2">
                                                                    <h5 class="name-meta mb-0 fz-16">{{ shortName(members.first_name ,members.last_name)}}</h5>
                                                                    <!-- <h6 class="text-black mb-0 fz-12">{{ (members.profileType == 1)?'Agency':(members.profileType == 2)? 'Client':'Freelancer' }}</h6> -->
                                                                    <h6 class="text-black mb-0 fz-12" v-if="members.chat_invitation_id == 0">{{ (members.profileType == 1)?'(Admin)':(members.profileType == 2)? '(Owner)':'(Admin)' }}</h6> 
                                                                    <h6 class="text-black mb-0 fz-12" v-else-if="members.chat_invitation_id != 0">{{ (members.Invitedpermission == 1)?'(Admin)':'(Member)' }}</h6> 
                                                                </div>
                                                            </div>
                                                            <template v-if="$store.state.accountType != 'freelancer' && members.chat_invitation_id != 0 && members.chat_invite_created_by == $store.state.currentUserData.login_master_id">
                                                                <div class="ml-auto" v-if="chatroomdata.chat_invitation_permission == undefined || chatroomdata.chat_invitation_permission == ''">
                                                                    <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                                        <template #button-content>
                                                                            <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                                        </template>
                                                                        <b-dropdown-item href="javascript:void(0);" @click="removeUserFromChat(members.chat_invitation_id,'remove')">
                                                                            <!-- <i class="fa fa-trash" aria-hidden="true"></i> -->
                                                                            Remove
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>
                                                                </div>
                                                                <div class="ml-auto" v-else-if="chatroomdata.chat_invitation_permission == 1">
                                                                    <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                                        <template #button-content>
                                                                            <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                                        </template>
                                                                        <b-dropdown-item href="javascript:void(0);" v-if="$store.state.currentUserData.permission.user_id == members.chat_invited_user_id" @click="removeUserFromChat(members.chat_invitation_id,'leave')">
                                                                            Leave room
                                                                        </b-dropdown-item>
                                                                        <b-dropdown-item href="javascript:void(0);" v-else @click="removeUserFromChat(members.chat_invitation_id,'remove')">
                                                                            <!-- <i class="fa fa-trash" aria-hidden="true"></i> -->
                                                                            Remove
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>
                                                                </div>
                                                                <div class="ml-auto" v-else-if="chatroomdata.chat_invitation_permission == 2 && $store.state.currentUserData.permission.user_id == members.chat_invited_user_id">
                                                                    <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                                        <template #button-content>
                                                                            <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                                        </template>
                                                                        <b-dropdown-item href="javascript:void(0);" @click="removeUserFromChat(members.chat_invitation_id,'leave')">
                                                                            Leave room
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="sideBar-body" v-if="$store.state.accountType != 'freelancer'">
                                                        <a href="javascript:void(0)" class="d-flex align-items-center">
                                                            <div class="sideBar-main">
                                                                <div class="sideBar-name">
                                                                    <h5 class="name-meta mb-0 fz-16 text-theme" v-if="$store.state.accountType == 'client' && $store.state.listAccount.user != null  && $store.state.listAccount.user !='' && $store.state.currentUserData.login_master_id == $store.state.listAccount.user.user_id" v-b-modal.add-people v-on:click="openAddpeopleModal()"><i class="fa fa-plus"></i> Add People</h5>
                                                                    <h5 class="name-meta mb-0 fz-16 text-theme" v-else-if="$store.state.accountType == 'agency' && $store.state.currentUserData.login_master_id == $store.state.listAccount.company.login_master_id" v-b-modal.add-people v-on:click="openAddpeopleModal()"><i class="fa fa-plus"></i> Add People</h5>
                                                                    <!-- <span class="text-muted mb-0 fz-14">AWS DevOps Developer</span>  -->
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="compose-sideBar right-chat-no-data sidesection" v-else >
                                                    <div class="row sideBar-body">
                                                        <div class="col-sm-12 col-xl-12 sideBar-avatar">
                                                            <div class="image-holder">
                                                                <img src="/web/group.png" alt="Chat" class="img-fluid">
                                                            </div>
                                                            <h4 class="name-meta fw-600 mb-0 fz-16 text-muted">No People Found</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                        <!-- this modal is for add people -->
                                        <b-modal id="add-people" size="lg" title="Add People">
                                            <div class="content-wrap">
                                                <b-form>
                                                    <b-form-group>
                                                        <label class="fz-18">Add People</label><br>
                                                        <!--<b-form-input placeholder="Enter name"></b-form-input>-->
                                                        <b-form-select 
                                                            v-model="addPeople.member_permission_id"
                                                            :options="invitedUser"
                                                            value-field="members_permission_id"
                                                            text-field="first_name"
                                                            @input="$v.addPeople.$touch()"
                                                            @blur="$v.addPeople.$touch()"
                                                            class="form-control"></b-form-select>
                                                            <div class="error" v-if="addPeopleError[0]">{{addPeopleError[0]}}</div>

                                                    </b-form-group>
                                                    <b-form-group>
                                                        <label class="fz-18 mt-3 mb-2">Permissions</label><br>
                                                        <b-form-radio class="mb-0 d-flex align-items-end" name="some-radios" v-model="addPeople.permission" value="1">Room Admin</b-form-radio>
                                                        <p class="mb-2 d-flex align-items-end text-muted">Add as a particiption who can also add and remove other particiption.</p>
                                                        <b-form-radio class="mb-0 d-flex align-items-normal "  name="some-radios" v-model="addPeople.permission" value="2">Room Member</b-form-radio>
                                                        <p class="mb-2 d-flex align-items-end text-muted">Add as a particiption in this conversation.</p>
                                                        <div class="error" v-if="addPeoplePermissionError[0]">{{addPeoplePermissionError[0]}}</div>
                                                    </b-form-group>
                                                </b-form>
                                            </div>
                                            <template #modal-footer>
                                                <div class="w-100 text-right">
                                                    <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('add-people')">Cancel</a>
                                                    <b-button size="lg" class="btn btn-theme" @click="submitAddPeopole">Add People</b-button>
                                                </div>
                                            </template>
                                        </b-modal>
                                    </b-card>

                                    <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-0 border-none" role="tab">
                                            <div class="row newMessage-heading">
                                                <div class="row newMessage-main bg-grey">
                                                    <b-col xl="12" lg="12" sm="12" class="p-0 d-flex align-items-center" v-b-toggle.accordion-2>
                                                        <h5 class="mb-0 fz-16 d-block">Files</h5>
                                                        <div class="ml-auto">
                                                            <i class="fa fa-chevron-down text-black" aria-hidden="true"></i>
                                                        </div>
                                                    </b-col>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                            <div class="image-grid-wrapper" v-if="filesData.length > 0" >
                                                <div class="image-flex-wrapper" v-for="(file,index) in filesData" :key="index">
                                                    <div class="image-wrap-inner d-flex align-items-center cursor-pointer" @click="downloadFile(file.media_withoutbasepath)">
                                                        <div class="content d-flex align-items-center">
                                                            <div class="avtar-wrap">
                                                                <img :src="file.media" alt="Image 2" v-if="file.file_type =='image/png' || file.file_type =='image/jpeg'">
                                                                <i class="fa fa-file-pdf-o" aria-hidden="true" v-else-if="file.file_type =='application/pdf'"></i>
                                                                <i class="fa fa-file-excel-o" aria-hidden="true" v-else-if="file.file_type =='application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></i>
                                                                <i class="fa fa-file-text" aria-hidden="true" v-else></i>
                                                            </div>
                                                            <div class="content ml-2">
                                                                <h6 class="mb-0">{{file.message}}</h6>
                                                                <p class="text-muted mb-0">{{shortName(file.first_name,file.last_name)}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="ml-auto">
                                                            <p class="mb-0">{{file.size | sizeInMb}}</p> 
                                                            <p class="mb-0">{{file.date}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="compose-sideBar right-chat-no-data sidesection" v-else >
                                                <div class="row sideBar-body">
                                                    <div class="col-sm-12 col-xl-12 sideBar-avatar">
                                                        <div class="image-holder">
                                                            <img src="/web/file.png" alt="Chat" class="img-fluid">
                                                        </div>
                                                        <h4 class="name-meta fw-600 mb-0 fz-16 text-muted">No File's Found</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </b-card>

                                    <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-0 border-none" role="tab">
                                            <div class="row newMessage-heading">
                                                <div class="row newMessage-main bg-grey">
                                                    <b-col xl="12" lg="12" sm="12" class="p-0 d-flex align-items-center" v-b-toggle.accordion-3>
                                                        <h5 class="mb-0 fz-16 d-block"> Personal Notepad</h5>
                                                        <div class="ml-auto">
                                                            <i class="fa fa-chevron-down text-black" aria-hidden="true"></i>
                                                        </div>
                                                    </b-col>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <b-form-textarea id="textarea" class="mt-3" v-model="personalNotesArr.note" placeholder="Enter Notes..." rows="8" max-rows="10"></b-form-textarea>
                                                <a href="javascript:void(0);" class="btn-theme text-center mt-3 d-block" v-if="chatroomdata!=''" @click="addPersonalNote(personalNotesArr)">Save</a>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <b-sidebar id="sidebar-left-chat" :visible="sidebarVisible" title="Recent chats" no-header v-if="windowResolution" shadow>
                    <header class="b-sidebar-header p-3">
                        <div class="d-flex align-items-center">
                            <h4 class="mb-0">
                                <strong id="sidebar-left-chat___title__">Recent chats</strong>
                            </h4>
                            <div class="ml-auto">
                                <button type="button" class="text-dark" @click="$router.go(-1)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </header>
                    <div class="px-0 py-0">
                        <div class="side-one">
                            <div class="row heading searchbox">
                                <div class="col-sm-12 searchBox-inner">
                                    <div class="d-flex align-items-center">
                                        <div class="form-group has-feedback width-95">
                                            <input id="searchText" v-model="search" type="text" class="form-control" @keyup="getChatlist()" placeholder="Search">
                                        </div>
                                        <div class="ml-auto width-5 bg-white">
                                            <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret>
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-v text-black mr-3" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black" @click="checkAutoresponse()" v-b-modal.out-of-office> Out of office</a>
                                                </b-dropdown-item>

                                                <b-modal id="out-of-office" size="lg" title="Out-of-Office Responder" centered hide-footer no-close-on-backdrop>
                                                    <form @submit.prevent="submitAutoResponse">
                                                    <div>
                                                        <div class="d-flex align-items-center mb-3">
                                                            <label class="switch">
                                                                <input type="checkbox" v-model="autoResponde.active" @click="toggleCheckbox">
                                                                <div class="slider round"></div>
                                                            </label>
                                                            <p class="ml-2 mb-0">Send automatic replies to incoming messages</p>
                                                        </div>
                                                        <span v-if="autoResponde.active">
                                                            <p>Your time zone is currently set to {{autoResponde.timezone}}. <a href="javascript:void(0);" class="text-theme" @click="changeTimeZone = true;">Change</a></p>
                                                            <p v-if="changeTimeZone">Select timezone for out of office response.</p>
                                                            <b-row v-if="changeTimeZone">
                                                                <b-col xl="6" lg="6" sm="12">
                                                                    <b-form-group>
                                                                        <b-form-select
                                                                        v-model="autoResponde.timezone_id"
                                                                        :options="timezoneList"
                                                                        class="form-control"
                                                                        value-field="timezone_id"
                                                                        text-field="name"
                                                                        :error-messages="timeZoneError"
                                                                        @input="$v.autoResponde.timezone_id.$touch()"
                                                                        @blur="$v.autoResponde.timezone_id.$touch()"
                                                                        ></b-form-select>
                                                                        <div class="error" v-if="timeZoneError[0]">{{timeZoneError[0]}}</div>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col xl="6" lg="6" sm="12">
                                                                    <b-form-group>
                                                                        <label class="fz-16">First day</label>
                                                                        <b-form-datepicker  v-model="autoResponde.from_date" :value="autoResponde.from_date" :min="currentDate" @input="autoResponde.to_date = autoResponde.from_date" required class="mb-2"></b-form-datepicker>
                                                                        <div class="error" v-if="fromDateError[0]">{{fromDateError[0]}}</div>
                                                                        <div class="error" v-if="autoResponde.from_date < currentDate">
                                                                            <div class="up-icon"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 14 14" role="img"><path fill-rule="evenodd" d="M8 7.927V9H6V2h2v5.927zM7 12a1 1 0 110-2 1 1 0 010 2zM7 0C3.15 0 0 3.15 0 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7z"></path></svg></div>
                                                                            Date should be greater than {{currentDate}}
                                                                        </div>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col xl="6" lg="6" sm="12">
                                                                    <b-form-group>
                                                                        <label class="fz-16">Last day</label>
                                                                        <b-form-datepicker  v-model="autoResponde.to_date" :value="autoResponde.to_date" :min="new Date(autoResponde.from_date).toISOString().substr(0, 10)"  required class="mb-2"></b-form-datepicker>
                                                                        <div class="error" v-if="toDateError[0]">{{toDateError[0]}}</div>
                                                                        <div class="error" v-if="autoResponde.to_date < currentDate">
                                                                            <div class="up-icon"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 14 14" role="img"><path fill-rule="evenodd" d="M8 7.927V9H6V2h2v5.927zM7 12a1 1 0 110-2 1 1 0 010 2zM7 0C3.15 0 0 3.15 0 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7z"></path></svg></div>
                                                                            Date should be greater than {{currentDate}}
                                                                        </div>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col xl="12" lg="12" sm="12">
                                                                    <b-form-group>
                                                                        <label class="fz-16">Your message</label>
                                                                        <b-form-textarea
                                                                        id="textarea"
                                                                        v-model="autoResponde.message"
                                                                        placeholder="Your message here..."
                                                                        rows="3"
                                                                        max-rows="6"
                                                                        ></b-form-textarea>
                                                                        <div class="error" v-if="messageError[0]">{{messageError[0]}}</div>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <p class="mt-3" v-if="$store.state.accountType != 'client'"><strong>Note:</strong> This setting does not affect your <a href="javascript:void(0);" class="text-theme">freelancer availability</a>. You can adjust your availability on the <a href="javascript:void(0);" class="text-theme">Find Work</a> page</p>
                                                            <p><b>You will appear as Out of Office across all your companies on Qapin.</b></p>
                                                        </span>
                                                    </div>
                                                    <template>
                                                        <div class="w-100 text-right">
                                                            <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('out-of-office');$v.$reset()">
                                                                Cancel
                                                            </a>
                                                            <b-button size="lg" class="btn btn-theme" type="submit"
                                                            :disabled="(autoResponde.from_date > currentDate ) ? true : false "
                                                            >
                                                                Save
                                                            </b-button>
                                                        </div>
                                            
                                                    </template>
                                                    </form>
                                                </b-modal>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <label class="text-black fz-16"> Filter rooms by</label>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'ASC';getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='ASC'"></i> All rooms</a>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'DESC'; getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='DESC'"></i> All rooms, recent first</a>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'unread'; getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='unread'"></i> Unread only</a>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'interview'; getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='interview'"></i> Interview only</a>
                                                </b-dropdown-item> 
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <a href="javascript:void(0);" class="text-black ml-3" @click="sort = 'contract'; getChatlist()"> <i class="fa fa-check text-muted mr-3" aria-hidden="true" v-if="sort =='contract'"></i> Contract only</a>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="contract-options p-3 bg-white border-bottom" v-if="sort!='' && sort!='ASC'"> -->
                            <div class="contract-options p-3 bg-white border-bottom" v-if="sort!='' && sort!='DESC'">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <i class="fa fa-sliders mr-2" aria-hidden="true"></i>
                                        <!-- <span class="text-muted">{{(sort=='DESC')?'All rooms, recent first':(sort=='unread')?'Unread only':(sort=='interview')?'Interview only':(sort=='contract')?'Contract only':''}}</span> -->
                                        <span class="text-muted">{{(sort=='ASC')?'All rooms':(sort=='unread')?'Unread only':(sort=='interview')?'Interview only':(sort=='contract')?'Contract only':''}}</span>
                                    </div>
                                    <div class="ml-auto">
                                        <a href="javascript:void(0);" @click="sort ='DESC'; getChatlist()">
                                            <i class="fa fa-times text-black" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-name-wrap" v-if="roomlist.length > 0 ">
                                <template  >
                                    <div class="row sideBar-body" v-for="(data,index) in roomlist" :key="index" :class="(data.chat_room_id == chatroomdata.chat_room_id)?'unread':''"  @click="sidebarVisible = false; (data.chat_room_id == chatroomdata.chat_room_id || !chatroomResponse) ? '': getselectedusermessage(data);">
                                        <a href="javascript:void(0)" @click="sidebarVisible = false; (data.chat_room_id == chatroomdata.chat_room_id || !chatroomResponse) ? '': getselectedusermessage(data);" v-if="$store.state.accountType=='client'" class="d-flex align-items-start p-0" v-b-toggle.sidebar-left-chat :class="(data.chat_room_id == chatroomdata.chat_room_id && !chatroomResponse)?'cursor-progress':(data.chat_room_id != chatroomdata.chat_room_id && !chatroomResponse)?'cursor-block':''">
                                            <div class="sideBar-avatar">
                                                <div class="avatar-icon">
                                                    <img :src="data.user_image">
                                                    <span class="status-icon" :class="(checkRoomUserStatus(data) == true) ? 'bg-success' : ''"></span>
                                                </div>
                                            </div>
                                            <div class="sideBar-main">
                                                <div class="sideBar-name">
                                                    <h5 class="name-meta mb-0 fz-16 p-0">{{data.user_first_name}} {{data.user_last_name}}</h5>
                                                    <h6 class="text-black mb-0 fz-14 job-title">{{data.job_title}} </h6>
                                                    <!-- <div class="absolute w-4 h-4 text-xs text-center text-white rounded-full bg-primary-500 notification-status">0</div> -->
                                                    <span class="text-muted mb-0 fz-14" v-html="getHtmlContent(data.message)"></span>
                                                </div>
                                                <div class="sideBar-time">
                                                    <span class="time-meta pull-right">{{data.time}}</span>
                                                    <div class="absolute w-4 h-4 text-xs text-center text-white rounded-full bg-primary-500 notification-status" v-if="data.unreadMessage > 0">{{data.unreadMessage}}</div>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0)" @click="sidebarVisible = false; (data.chat_room_id == chatroomdata.chat_room_id || !chatroomResponse) ? '' : getselectedusermessage(data);" v-else-if="data.status == 1" class="d-flex align-items- p-0" v-b-toggle.sidebar-left-chat :class="(data.chat_room_id == chatroomdata.chat_room_id && !chatroomResponse)?'cursor-progress':(data.chat_room_id != chatroomdata.chat_room_id && !chatroomResponse)?'cursor-block':''">
                                            <div class="sideBar-avatar">
                                                <div class="avatar-icon">
                                                    <img :src="data.client_image">
                                                    <span class="status-icon" :class="(checkRoomUserStatus(data) == true) ? 'bg-success' : ''"></span>
                                                </div>
                                            </div>
                                            <div class="sideBar-main">
                                                <div class="sideBar-name">
                                                    <h5 class="name-meta mb-0 fz-16 p-0">{{data.client_first_name}} {{data.client_last_name}}</h5>
                                                    <h6 class="text-black mb-0 fz-16 job-title">{{data.job_title}}</h6>
                                                    <span class="text-muted mb-0 fz-14" v-html="getHtmlContent(data.message)"></span>
                                                </div>
                                                <div class="sideBar-time">
                                                    <span class="time-meta pull-right">{{data.time}}</span>
                                                <div class="absolute w-4 h-4 text-xs text-center text-white rounded-full bg-primary-500 notification-status" v-if="data.unreadMessage > 0">{{data.unreadMessage}}</div>

                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </template>
                            </div>
                            <div class="chat-name-wrap left-chat-no-data sidesection text-center" v-else>
                                <div class="row sideBar-body" >
                                    <a href="javascript:void(0)">
                                        <div class="col-sm-12 col-xl-12 sideBar-avatar">
                                            <div class="image-holder">
                                                <img src="/web/communications.png" alt="Chat" class="img-fluid">
                                            </div>
                                            <h4 class="fz-16 fw-600 mb-0 text-muted text-center">No active members found</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-sidebar>
                <b-sidebar id="sidebar-right-chat" title="Chat Room Details" right shadow>
                    <div v-if="!hidePeople">
                        <div class="side-two">
                            <template>
                                <div class="accordion p-0 border-none" role="tablist">
                                    <b-card no-body class="mb-1">
                                        <b-card-header  header-tag="header" class="p-0 border-none" role="tab">
                                            <div class="row newMessage-heading">
                                                <div class="row newMessage-main bg-grey">
                                                    <b-col xl="12" lg="12" sm="12" class="p-0 d-flex align-items-center togglePeople" v-b-toggle.accordion-1>
                                                        <h5 class="mb-0 fz-16 d-block">People</h5>
                                                        <div class="ml-auto">
                                                            <i class="fa fa-chevron-down text-black" aria-hidden="true"></i>
                                                        </div>
                                                    </b-col>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <b-collapse visible id="accordion-1" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="p-0">
                                                <div class="image-grid-wrapper" v-if="chatroomMembers.length > 0">
                                                    <div class="image-flex-wrapper" v-for="(members,index) in  chatroomMembers" :key="index">
                                                        <div class="image-wrap-inner d-flex align-items-center">
                                                            <div class="content d-flex align-items-center">
                                                                <div class="avtar-wrap">
                                                                    <img :src="members.profile_picture_path">
                                                                    <span class="status-icon" :class="(checkChatRoomMemberIsOnlineOroffline(members) == true) ? 'bg-success' : ''"></span>
                                                                </div>
                                                                <div class="content ml-2">
                                                                    <h5 class="name-meta mb-0 fz-16">{{shortName(members.first_name,members.last_name)}}</h5>
                                                                    <!-- <h6 class="text-black mb-0 fz-12">{{ (members.profileType == 1)?'Agency':(members.profileType == 2)? 'Client':'Freelancer' }}</h6> -->
                                                                    <h6 class="text-black mb-0 fz-12" v-if="members.chat_invitation_id == 0">{{ (members.profileType == 1)?'(Admin)':(members.profileType == 2)? '(Owner)':'(Admin)' }}</h6> 
                                                                    <h6 class="text-black mb-0 fz-12" v-else-if="members.chat_invitation_id != 0">{{ (members.Invitedpermission == 1)?'(Admin)':'(Member)' }} {{ chatroomdata.chat_invitation_permission }}</h6> 
                                                                </div>
                                                            </div>
                                                            <template v-if="$store.state.accountType != 'freelancer' && members.chat_invitation_id != 0 && members.chat_invite_created_by == $store.state.currentUserData.login_master_id">
                                                                <div class="ml-auto" v-if="chatroomdata.chat_invitation_permission == undefined || chatroomdata.chat_invitation_permission == ''">
                                                                    <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                                        <template #button-content>
                                                                            <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                                        </template>
                                                                        <b-dropdown-item href="javascript:void(0);" @click="removeUserFromChat(members.chat_invitation_id,'remove')">
                                                                            <!-- <i class="fa fa-trash" aria-hidden="true"></i> -->
                                                                            Remove
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>
                                                                </div>
                                                                <div class="ml-auto" v-else-if="chatroomdata.chat_invitation_permission == 1">
                                                                    <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                                        <template #button-content>
                                                                            <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                                        </template>
                                                                        <b-dropdown-item href="javascript:void(0);" v-if="$store.state.currentUserData.permission.user_id == members.chat_invited_user_id" @click="removeUserFromChat(members.chat_invitation_id,'leave')">
                                                                            Leave room
                                                                        </b-dropdown-item>
                                                                        <b-dropdown-item href="javascript:void(0);" v-else @click="removeUserFromChat(members.chat_invitation_id,'remove')">
                                                                            <!-- <i class="fa fa-trash" aria-hidden="true"></i> -->
                                                                            Remove
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>
                                                                </div>
                                                                <div class="ml-auto" v-else-if="chatroomdata.chat_invitation_permission == 2 && $store.state.currentUserData.permission.user_id == members.chat_invited_user_id">
                                                                    <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                                        <template #button-content>
                                                                            <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                                        </template>
                                                                        <b-dropdown-item href="javascript:void(0);" @click="removeUserFromChat(members.chat_invitation_id,'leave')">
                                                                            Leave room
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="sideBar-body" v-if="$store.state.accountType != 'freelancer'">
                                                        <a href="javascript:void(0)" class="d-flex align-items-center">
                                                            <div class="sideBar-main">
                                                                <div class="sideBar-name">
                                                                    <h5 class="name-meta mb-0 fz-16 text-theme" v-if="$store.state.accountType == 'client' && $store.state.listAccount.user != null  && $store.state.listAccount.user !='' && $store.state.currentUserData.login_master_id == $store.state.listAccount.user.user_id" v-b-modal.add-people v-on:click="openAddpeopleModal()"><i class="fa fa-plus"></i> Add People</h5>
                                                                    <h5 class="name-meta mb-0 fz-16 text-theme" v-else-if="$store.state.accountType == 'agency' && $store.state.currentUserData.login_master_id == $store.state.listAccount.company.login_master_id" v-b-modal.add-people v-on:click="openAddpeopleModal()"><i class="fa fa-plus"></i> Add People</h5>
                                                                    <!-- <span class="text-muted mb-0 fz-14">AWS DevOps Developer</span>  -->
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="compose-sideBar right-chat-no-data sidesection" v-else >
                                                    <div class="row sideBar-body">
                                                        <div class="col-sm-12 col-xl-12 sideBar-avatar">
                                                            <div class="image-holder">
                                                                <img src="/web/group.png" alt="Chat" class="img-fluid">
                                                            </div>
                                                            <h4 class="name-meta fw-600 mb-0 fz-16 text-muted">No People Found</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                        <!-- this modal is for add people -->
                                        <b-modal id="add-people" size="lg" title="Add People">
                                            <div class="content-wrap">
                                                <b-form>
                                                    <b-form-group>
                                                        <label class="fz-18">Add People</label><br>
                                                        <!--<b-form-input placeholder="Enter name"></b-form-input>-->
                                                        <b-form-select 
                                                            v-model="addPeople.member_permission_id"
                                                            :options="invitedUser"
                                                            value-field="members_permission_id"
                                                            text-field="first_name"
                                                            @input="$v.addPeople.$touch()"
                                                            @blur="$v.addPeople.$touch()"
                                                            class="form-control"></b-form-select>
                                                            <div class="error" v-if="addPeopleError[0]">{{addPeopleError[0]}}</div>

                                                    </b-form-group>
                                                    <b-form-group>
                                                        <label class="fz-18 mt-3 mb-2">Permissions</label><br>
                                                        <b-form-radio class="mb-0 d-flex align-items-end" name="some-radios" v-model="addPeople.permission" value="1">Room Admin</b-form-radio>
                                                        <p class="mb-2 d-flex align-items-end text-muted">Add as a particiption who can also add and remove other particiption.</p>
                                                        <b-form-radio class="mb-0 d-flex align-items-normal "  name="some-radios" v-model="addPeople.permission" value="2">Room Member</b-form-radio>
                                                        <p class="mb-2 d-flex align-items-end text-muted">Add as a particiption in this conversation.</p>
                                                        <div class="error" v-if="addPeoplePermissionError[0]">{{addPeoplePermissionError[0]}}</div>
                                                    </b-form-group>
                                                </b-form>
                                            </div>
                                            <template #modal-footer>
                                                <div class="w-100 text-right">
                                                    <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('add-people')">Cancel</a>
                                                    <b-button size="lg" class="btn btn-theme" @click="submitAddPeopole">Add People</b-button>
                                                </div>
                                            </template>
                                        </b-modal>
                                    </b-card>

                                    <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-0 border-none" role="tab">
                                            <div class="row newMessage-heading">
                                                <div class="row newMessage-main bg-grey">
                                                    <b-col xl="12" lg="12" sm="12" class="p-0 d-flex align-items-center" v-b-toggle.accordion-2>
                                                        <h5 class="mb-0 fz-16 d-block">Files</h5>
                                                        <div class="ml-auto">
                                                            <i class="fa fa-chevron-down text-black" aria-hidden="true"></i>
                                                        </div>
                                                    </b-col>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                            <div class="image-grid-wrapper" v-if="filesData.length > 0" >
                                                <div class="image-flex-wrapper" v-for="(file,index) in filesData" :key="index">
                                                    <div class="image-wrap-inner d-flex align-items-center cursor-pointer" @click="downloadFile(file.media_withoutbasepath)">
                                                        <div class="content d-flex align-items-center">
                                                            <div class="avtar-wrap">
                                                                <img :src="file.media" alt="Image 2" v-if="file.file_type =='image/png' || file.file_type =='image/jpeg'">
                                                                <i class="fa fa-file-pdf-o" aria-hidden="true" v-else-if="file.file_type =='application/pdf'"></i>
                                                                <i class="fa fa-file-excel-o" aria-hidden="true" v-else-if="file.file_type =='application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></i>
                                                                <i class="fa fa-file-text" aria-hidden="true" v-else></i>
                                                            </div>
                                                            <div class="content ml-2">
                                                                <h6 class="mb-0">{{file.message}}</h6>
                                                                <p class="text-muted mb-0">{{shortName(file.first_name,file.last_name)}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="ml-auto">
                                                            <p class="mb-0">{{file.size | sizeInMb}}</p> 
                                                            <p class="mb-0">{{file.date}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="compose-sideBar right-chat-no-data sidesection" v-else >
                                                <div class="row sideBar-body">
                                                    <div class="col-sm-12 col-xl-12 sideBar-avatar">
                                                        <div class="image-holder">
                                                            <img src="/web/file.png" alt="Chat" class="img-fluid">
                                                        </div>
                                                        <h4 class="name-meta fw-600 mb-0 fz-16 text-muted">No File's Found</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </b-card>

                                    <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-0 border-none" role="tab">
                                            <div class="row newMessage-heading">
                                                <div class="row newMessage-main bg-grey">
                                                    <b-col xl="12" lg="12" sm="12" class="p-0 d-flex align-items-center" v-b-toggle.accordion-3>
                                                        <h5 class="mb-0 fz-16 d-block"> Personal Notepad</h5>
                                                        <div class="ml-auto">
                                                            <i class="fa fa-chevron-down text-black" aria-hidden="true"></i>
                                                        </div>
                                                    </b-col>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <b-form-textarea id="textarea" class="mt-3" v-model="personalNotesArr.note" placeholder="Enter Notes..." rows="8" max-rows="10"></b-form-textarea>
                                                <a href="javascript:void(0);" class="btn-theme text-center mt-3 d-block" v-if="chatroomdata!=''" @click="addPersonalNote(personalNotesArr)">Save</a>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </template>
                        </div>
                    </div>
                </b-sidebar>
            </div>
        </div>
    </div>
</template>
<script>
var that = '';
import { mapActions, mapGetters} from "vuex";
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import chatBoxData from '../../components/chat/chatBoxData.vue'
// import { saveAs } from 'file-saver';
export default {
components:{
    chatBoxData,
    // saveAs
},
mixins: [validationMixin],
validations: { 
    autoResponde        :{
        timezone_id             : {required},
        from_date               : {required},
        to_date                 : {required},
        message                 : {required},
    },
    addPeople           :{
        chat_room_id            : {required},
        member_permission_id    : {required},
        permission              : {required},
        created_by              : {required},
    }
},
data() {
    return {
        chatroomdata        : '',
        currentDate         : new Date().toISOString().substr(0, 10),
        search              : '',
        sort                : 'DESC',
        // roomlist            : [],
        messagelist         : [],
        getmessageparams    : {
            chat_room_id        : '',
            start_limit         : 0,
            end_limit           : 10,
            cycle               : '',
            lastId              : '',
            firstId             : '',
        },
        currentUser         : '',
        chatroomMembers     : [],
        hidePeople          : false,
        autoResponde        : {
            active              : false,
            timezone_id         : this.$store.state.currentUserOwnerData.timezone,
            timezone            : this.$store.state.currentUserOwnerData.timezone_name,
            from_date           : this.currentDate,
            to_date             : '',
            message             : '',
        },
        changeTimeZone      : false,
        personalNotesArr    : {
            chat_note_id        : '',
            note                : '',
            user_id             : this.$store.state.currentUserData.login_master_id,
            chat_room_id        : '',
        },
        filesData           : [],
        form                : {
            email               : '',
            name                : '',
            food                : null,
            checked             : []
        },
        invitedUser         : [{ first_name: 'Select One', members_permission_id: null }],
        show                : true,
        selected            : '',
        addPeople           : {
            chat_room_id            : '',
            user_id                 : '',
            member_permission_id    : '',
            type                    : '',
            permission              : 2,
            created_by              : this.$store.state.currentUserData.login_master_id,
        },
        callChatRoomApi     : false,
        sidebarVisible      : true,
        openChatRoomById    : false,
    }
},
methods: {
    ...mapActions(["genericAPIPOSTRequest","cancelApiRequest","fetchCompanyAccountList","UpdateCurrentUrl"]),
    ...mapActions("global",["UpdateMessageRequestData","UpdateChatRoomResponse","fetchTimeZone","UpdateRequestRoomData","UpdateInvitationRequestRoomData","EmptyMessageData","UpdateChatLoaderStatus"]),
    
    // this fun is for to check chat room members online status 
    checkChatRoomMemberIsOnlineOroffline(_v){
        var status = that.checkUserOnlineOrOffline(_v);
        return status
    },
    // this fun is for to check room other user online status
    checkRoomUserStatus(_v){
        console.log('_v.onlineData', _v.onlineData)
        return true;
        // var res  = _v.onlineData?.filter(data=>{
        //     that.chatroomMembers?.map((member,index)=>{
        //         if(data.login_master_id == member.login_master_id){
        //             that.chatroomMembers[index].online_status = data.online_status
        //             that.chatroomMembers[index].online_timestamp = data.online_timestamp
        //         }
        //     });
        //     if(that.$store.state.accountType != 'client'){
        //         if (_v.client_id == data.login_master_id){
        //             return data;
        //         }
        //     }else{
        //         if (_v.user_id == data.login_master_id){
        //             return data;
        //         }
        //     }
        // });
        // if(res[0] != ''){
        //     var status = that.checkUserOnlineOrOffline(res[0]);
        //     return status
        // }else{
        //     return false;
        // }
    },
    //this fun is for to update unread message count
    updateUnreadMessage(_i){
        this.roomlist.map(data=>{
            if(data.chat_room_id == _i){
                data.unreadMessage = 0
            }
        });
    },
    // out of office switch box method 
    toggleCheckbox() {
        this.autoResponde.active = !this.autoResponde.active;
    },
    // this fun is for to add or update the auto response message
    async submitAutoResponse(){
        if(this.autoResponde.from_date < this.currentDate){
            return false;
        }
        this.$v.autoResponde.$touch(); 
        if (!this.$v.autoResponde.$invalid ) { 
            var res = await this.genericAPIPOSTRequest({
                requestUrl:this.autoResponde.url,
                params: {
                    auto_response_id:this.autoResponde.auto_response_id,
                    timezone_id:this.autoResponde.timezone_id,
                    from_date:this.autoResponde.from_date,
                    to_date:this.autoResponde.to_date,
                    message:this.autoResponde.message,
                },
            });
            if(res == true){
                if(this.autoResponde.url=='addAutoresponse'){
                    this.$toastr.s('Auto response added')
                }else{
                    this.$toastr.s('Auto response updated')
                }
                this.$bvModal.hide('out-of-office');
            }
        }
    },
    // this fun is for to check auto response to message
    async checkAutoresponse(){
        var res = await this.genericAPIPOSTRequest({
            requestUrl:'getAutoresponse',
            params: {},
        });
        if (res!='') {
            this.autoResponde = {
                url:'updateAutoresponse',
                active:true,
                timezone_id: res.timezone_id,
                timezone: res.timezone,
                from_date:res.from_date,
                to_date:res.to_date,
                message:res.message,
                auto_response_id:res.auto_response_id,
            }
        }else{
            this.autoResponde = {
                url:'addAutoresponse',
                active:false,
                timezone_id: this.$store.state.currentUserOwnerData.timezone,
                timezone: this.$store.state.currentUserOwnerData.timezone_name,
                from_date:this.currentDate,
                to_date:'',
                message:'',
            }
        }
    },
    // this fun is for to get chat user list
    async getChatlist(_v=null){
        var that = this;
        var res;
        if(that.$store.state.accountType == 'freelancer'){
            res = await that.UpdateRequestRoomData({params:{'user_id':that.$store.state.currentUserData.login_master_id,'status':1,'userType': that.$store.state.accountType,'title': that.search,'sort': that.sort}})
        }else if(that.$store.state.accountType == 'client' && that.$store.state.listAccount.user != null && that.$store.state.listAccount.user != '' && that.$store.state.currentUserData.login_master_id == that.$store.state.listAccount.user.user_id){
            res = await that.UpdateRequestRoomData({params:{'client_id':that.$store.state.currentUserData.login_master_id,'status':1,'userType': that.$store.state.accountType,'title': that.search,'sort': that.sort}});
        }else if(that.$store.state.accountType == 'agency' && that.$store.state.currentUserData.login_master_id == that.$store.state.listAccount.company.login_master_id){
            res = await that.UpdateRequestRoomData({params:{'agency_id':that.$store.state.currentUserData.login_master_id,'status':1,'userType': that.$store.state.accountType,'title': that.search,'sort': that.sort}});
        }else if(that.$store.state.accountType == 'client' && that.$store.state.listAccount.user != null && that.$store.state.listAccount.user != '' && that.$store.state.currentUserData.login_master_id != that.$store.state.listAccount.user.user_id ){
            res = await that.UpdateInvitationRequestRoomData({params:{'user_id':that.$store.state.currentUserData.permission.user_id,'member_permission_id': that.$store.state.currentUserData.permission.members_permission_id,'title': that.search,'sort': that.sort}});
        }else if(that.$store.state.accountType == 'client' && that.$store.state.currentUserData.permission.user_id == that.$store.state.listAccount.qp_user.user_id){
            res = await that.UpdateInvitationRequestRoomData({params:{'user_id':that.$store.state.currentUserData.permission.user_id,'member_permission_id': that.$store.state.currentUserData.permission.members_permission_id,'title': that.search,'sort': that.sort}});
        }else if(that.$store.state.accountType == 'agency' && that.$store.state.currentUserData.login_master_id != that.$store.state.listAccount.company.login_master_id){
            res = await that.UpdateInvitationRequestRoomData({params:{'user_id':that.$store.state.currentUserData.permission.user_id,'member_permission_id': that.$store.state.currentUserData.permission.members_permission_id,'title': that.search,'sort': that.sort}});
        }
        if(res){
            if (that.roomlist.length > 0) {
                if(_v=='onload'){  
                    that.chatroomdata = that.roomlist[0];
                    that.getselectedusermessage(that.roomlist[0]);
                }
            }else{
                that.roomlist                           = [];
                that.chatroomMembers                    = [];
                that.filesData                          = [];
                that.personalNotesArr.note              = '';
                that.personalNotesArr.chat_note_id      = '';
                that.chatroomdata                       = '';
                that.currentUser                        = '';
                that.$refs.oprateChatbox.refreshChatMessage();
            }
        }
    },
    // this fun is for to set get message id
    async  getselectedusermessage(_i = null){

        this.UpdateChatRoomResponse(false);
        this.getchatroomMembers(_i.chat_room_id);
        this.getFiles(_i.chat_room_id);
        this.getPersonalNotes(_i.chat_room_id);
        // this.EmptyMessageData();
        this.chatroomdata = _i;
        this.currentUser =_i;
        this.UpdateChatLoaderStatus({param:true});
        setTimeout(() => {
            that.$refs.oprateChatbox.getselectedusermessage();
            // that.EmptyMessageData({params : []});
            that.UpdateMessageRequestData({params:{
                chat_room_id        : _i.chat_room_id,
                start_limit         : 0,
                end_limit           : 10,
                cycle               : 'first',
                lastId              : '',
                firstId             : '',
            }});
        }, 1000);
    },
    // this fun is for to get chat room members
    async getchatroomMembers(_i){
        this.chatroomMembers = [];
        this.getmessageparams.chat_room_id = _i;
        this.getmessageparams.start_limit = 0;
        var res = await this.genericAPIPOSTRequest({
            requestUrl:'chatRoomMembers',
            params: this.getmessageparams,
        });
        if (res.length > 0 ) {
            this.chatroomMembers = res;
        }
    },
    // this fun is for to get personal note of chat
    async getPersonalNotes(_i){
        this.personalNotesArr.chat_room_id          = _i;
        var res = await this.genericAPIPOSTRequest({
            requestUrl:'getChatNote',
            params: {
                'chat_room_id'  : _i,
                'user_id'       : this.personalNotesArr.user_id,
            },
        });
        if(res){
            this.personalNotesArr.note              = res.note;
            this.personalNotesArr.chat_note_id      = res.chat_note_id;
        }else{
            this.personalNotesArr.note              = '';
            this.personalNotesArr.chat_note_id      = '';
        }
    },
    // this fun is for to get personal note of chat
    async getFiles(_i){
        var res = await this.genericAPIPOSTRequest({
            requestUrl:'getChatFiles',
            params: {
                'chat_room_id'  : _i,
            },
        });
        if(res){
            this.filesData      = res;
        }else{
            this.filesData      = [];
        }
    },
    // this fun is for to open add people modal box
    async openAddpeopleModal(){
        var res = await this.genericAPIPOSTRequest({
            requestUrl      : "getsendUserInvitation",
            params          : {
                status          : 1,
                data            : this.getCurrentUserIdRequest(),
                type            : this.$store.state.accountType,
            },
        });
        if (res) {
            var that            = this;
            that.invitedUser    = [];
            that.invitedUser    = res;
            that.chatroomMembers.map(member=>{
                that.invitedUser.map((data,index)=>{
                    if(data.members_permission_id != undefined && data.members_permission_id == member.member_permission_id){
                        that.invitedUser.splice(index,1)
                    }
                })
            });
        }
    },
    // this fun is for to submit add people data
    async submitAddPeopole(){
        this.addPeople.chat_room_id = this.chatroomdata.chat_room_id;
        this.$v.addPeople.$touch(); 
        if (!this.$v.addPeople.$invalid) { 
            this.invitedUser.map(data=>{
                if(data.members_permission_id == this.addPeople.member_permission_id){
                    this.addPeople.user_id  = data.user_id;
                    this.addPeople.type     = data.invited_acc_type;
                }
            })
            var res = await this.genericAPIPOSTRequest({
                requestUrl      : "addChatInvitation",
                params          : this.addPeople,
            });
            if(res){
                this.$v.$reset();
                this.$toastr.s("User Added to chat Room");
                this.getchatroomMembers(this.chatroomdata.chat_room_id);
                this.$bvModal.hide('add-people')
                this.addPeople.member_permission_id  = '';
                this.addPeople.permission            = 2;
            }
        }
    },
    // this fun is for to remove member from chat
    async removeUserFromChat(_i,_t){
        var res = await this.genericAPIPOSTRequest({
            requestUrl      : "updateChatInvitation",
            params          : {
                chat_invitation_id  : _i,
                status              : 1,
            },
        });
        if(res){
            if(_t == 'remove'){
                this.$toastr.s('User removed');
                this.getchatroomMembers(this.chatroomdata.chat_room_id);
            }else if(_t == 'leave'){
                this.$toastr.s('Leave chat Room successfully');
                this.getChatlist('onload');
            }
        }else{
            if(_t == 'remove'){
                this.$toastr.e('User Not remove');
            }else if(_t == 'leave'){
                this.$toastr.e('Leave chat Room successfully');
            }
        }
    },
    async fetchAccountList(){
        var res = await this.genericAPIPOSTRequest({
            requestUrl: "company/getCompanyCount",
            params: { data: this.getLoginUserIdRequest() },
        });
        this.$store.dispatch("listAccount", res);
    }
},
created() {
    // that = this;
    // this.openChatRoomById = false;
},
mounted() {
    that = this;
    this.openChatRoomById = false;
    that.fetchTimeZone();
},
watch:{
    roomlist(){
        console.log('this.roomlist',this.roomlist);

        if(!this.callChatRoomApi && this.roomlist.length > 0 && (this.$route.query.chatroom == '' || this.$route.query.chatroom == undefined)){
            console.log('this.roomlist',1);
            this.callChatRoomApi = true;
            this.getChatlist('onload');
        }else if(this.roomlist.length > 0 && this.$route.query.chatroom != '' && this.$route.query.chatroom != undefined && !this.openChatRoomById){
            console.log('this.roomlist',2);
            this.openChatRoomById = true;
            var chatroomId = this.$route.query.chatroom;
            chatroomId = this.decodeId(chatroomId);
            this.chatroomdata = JSON.parse(chatroomId);
            this.getselectedusermessage(this.chatroomdata);
            this.getChatlist();
        }
        // this is for message count
        var previousMsgCount = 0;
        if(this.$store.state.accountType == 'agency' && this.$store.state.currentUserData.permission != '' && this.$store.state.currentUserData.permission != 'null' && this.$store.state.listAccount.company?.UnreadChatRoom > 0){
            console.log('this.roomlist',3);
            previousMsgCount = this.$store.state.listAccount.company.UnreadChatRoom;
        }else if(this.$store.state.accountType == 'freelancer' && this.$store.state.listAccount.qp_user?.UnreadChatRoom > 0){
            console.log('this.roomlist',4);
            previousMsgCount = this.$store.state.listAccount.qp_user.UnreadChatRoom;
        }else if(this.$store.state.accountType == 'client' && this.$store.state.currentUserData.permission != '' && this.$store.state.currentUserData.permission != 'null' && this.$store.state.listAccount.user?.UnreadChatRoom > 0){
            previousMsgCount = this.$store.state.listAccount.user.UnreadChatRoom
            console.log('this.roomlist',5);
        }
        var currentCount = 0;
        for(const arr of this.roomlist){
            if(arr.unreadMessage > 0){
                currentCount++
            }
        }
        console.log('',8, currentCount);
        if(previousMsgCount != currentCount){
            that.fetchAccountList();
        }
    },
    
},
computed:{
    ...mapGetters({timezoneList: "global/getTimeZone",roomlist : "global/getRoomData",chatroomResponse : "global/getchatroomResponse",windowResolution :"getmobileWindow"}),
    timeZoneError() {
        const errors = []
        if (!this.$v.autoResponde.timezone_id.$dirty) return errors 
        !this.$v.autoResponde.timezone_id.required && errors.push('Time Zone is required')
        return errors
    },
    fromDateError() {
        const errors = []
        if (!this.$v.autoResponde.from_date.$dirty) return errors 
        !this.$v.autoResponde.from_date.required && errors.push('From Date is required')
        return errors
    },
    toDateError() {
        const errors = []
        if (!this.$v.autoResponde.to_date.$dirty) return errors 
        !this.$v.autoResponde.to_date.required && errors.push('Last Day is required')
        return errors
    },
    messageError() {
        const errors = []
        if (!this.$v.autoResponde.message.$dirty) return errors 
        !this.$v.autoResponde.message.required && errors.push('Message is required')
        return errors
    },
    addPeopleError() {
        const errors = []
        if (!this.$v.addPeople.member_permission_id.$dirty) return errors 
        !this.$v.addPeople.member_permission_id.required && errors.push('Select People is required')
        return errors
    },
    addPeoplePermissionError() {
        const errors = []
        if (!this.$v.addPeople.permission.$dirty) return errors 
        !this.$v.addPeople.permission.required && errors.push('Select Permission is required')
        return errors
    },
},
filters:{
    sizeInMb(value){
        let  sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (value == 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));
        return Math.round(value / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
},
}
</script>