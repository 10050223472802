<template>
  <div class="search-filter-wrapper border-bottom pb-0 br-20 bg-white freelancer-wrap mt-12">
    <b-row>
      <b-col xl="12" lg="12" md="12" class="pb-0">
        <template v-if="!isPermissionForClient('offers-page')">
          <div class="text-center  px-4 py-4">
            <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
              <path d="M65.7535 6.47396C70.8988 2.90571 77.7184 2.90571 82.8637 6.47396C86.1714 8.76789 90.2618 9.63733 94.2166 8.88711C100.368 7.72012 106.598 10.4939 109.848 15.8464C111.936 19.2874 115.319 21.7454 119.237 22.6686C125.332 24.1047 129.895 29.1726 130.686 35.3839C131.195 39.377 133.286 42.9986 136.49 45.4356C141.473 49.2264 143.581 55.7122 141.777 61.7083C140.618 65.563 141.055 69.7219 142.99 73.2513C146.001 78.7414 145.288 85.5236 141.202 90.2677C138.575 93.3176 137.282 97.2947 137.615 101.306C138.133 107.546 134.723 113.452 129.06 116.124C125.42 117.842 122.621 120.949 121.294 124.749C119.228 130.661 113.711 134.669 107.451 134.807C103.427 134.895 99.6067 136.596 96.8481 139.527C92.5572 144.087 85.8866 145.505 80.1119 143.085C76.3995 141.529 72.2177 141.529 68.5053 143.085C62.7306 145.505 56.06 144.087 51.769 139.527C49.0105 136.596 45.1902 134.895 41.1659 134.807C34.9059 134.669 29.3887 130.661 27.3235 124.749C25.9958 120.949 23.1976 117.842 19.5571 116.124C13.8943 113.452 10.4845 107.546 11.0021 101.306C11.3348 97.2947 10.0425 93.3176 7.41543 90.2677C3.3289 85.5236 2.61606 78.7414 5.62693 73.2513C7.56254 69.7219 7.99966 65.563 6.84015 61.7083C5.03652 55.7122 7.1439 49.2264 12.1275 45.4356C15.3313 42.9986 17.4222 39.377 17.9308 35.3839C18.7219 29.1726 23.2851 24.1047 29.3797 22.6686C33.2977 21.7454 36.6809 19.2874 38.7697 15.8464C42.0188 10.4939 48.2488 7.72012 54.4005 8.88711C58.3554 9.63733 62.4458 8.76789 65.7535 6.47396Z" fill="#F2613C"/>
              <path d="M68.4151 83.8327H76.452L83.8639 55.4354L86.4536 44.1836H74.2195L72.5228 55.4354L68.4151 83.8327ZM68.5044 105.086C73.6837 105.086 77.2557 100.621 77.2557 96.1561C77.2557 92.3162 74.7553 89.4586 70.9154 89.4586C65.7361 89.4586 62.1641 94.1022 62.1641 98.4779C62.1641 102.407 64.6645 105.086 68.5044 105.086Z" fill="white"/>
            </svg>
            <h4 class="fz-20 fw-600 text-black">You don't have permission for this Section</h4>
            <p class="fz-14 fw-500 text-black mb-0">You are not allow to access Offers list.</p>
          </div>
        </template>
        <template v-else-if="proposalData.length > 0">
          <!-- //new -->
          <template v-for="(proposal, id) in proposalData" >
            <div class="job-card mb-2 offersec" :key="id">
              <div class="action-btn d-flex align-items-center offer-action-btn">
                <template>
                  <a class="btn btn-theme" href="javascript:void(0);"  @click="$router.push({name:'offerdeatils',path:'/client/offer-details',params:{ pid:proposal.uniq_id,uid:encodedid(proposal.user_id)}}).catch(()=>{})">View Offer</a>
                </template>
              </div>
              <div class="d-flex align-items-center profile-wrap mb-2">
                <div class="img-wrapper pos-rel mr-3">
                  <div class=" d-md-flex align-items-center mb-2">
                    <span class="online" :class="(checkUserOnlineOrOffline(proposal) == true) ? 'bg-success' : ''"></span>
                    <img :src="proposal.profile_picture_path" class="rounded-md  w-14 h-14">
                  </div>
                </div>
                <div class="ml-2">
                  <div class="d-md-flex align-items-center">
                    <h5 class="text-black fw-600 fz-18 mb-0 text-capitalize mr-2 client-search-name collapsed" v-b-toggle.sidebar-message @click="(jobDetails.is_delete != 1)?messageModel(proposal):''">{{shortName(proposal.first_name,proposal.last_name)}}</h5>
                    <ul class="minus--30 mb-0 collapsed">
                      <li class="fz-14 fw-600 text-muted">
                        {{(proposal.country_name!='' && proposal.country_name!= null)?proposal.country_name: ''}}
                        {{(proposal.state_name!='' && proposal.state_name!= null)?', '+proposal.state_name: ''}}
                        {{(proposal.city_name!='' && proposal.city_name!= null)?', '+proposal.city_name: ''}}
                        {{ (proposal.role_name!=''&& proposal.role_name != null)?' | '+proposal.role_name: '' }}
                      </li> 
                    </ul>
                  </div>
                  <ul class="align-items-center fz-20 fw-600 post text-black mb-0 client-search-page-ul collapsed"  v-b-toggle.sidebar-message @click="(jobDetails.is_delete != 1)?messageModel(proposal):''">
                    <li class="first-li text-capitalize fz-20">{{proposal.tagline}}</li> 
                  </ul>
                </div>
              </div>

              <b-row>
                <b-col xl="3" lg="3" md="3" sm="12" cols="6" v-if="($store.state.mobileWindow == true && proposal.originalAmount > 0)||($store.state.mobileWindow == false)">
                <h5 class="text-black mb-0 fz-16"><b>${{proposal.originalAmount}}</b> /Cost</h5>
                <!-- <h5 class="text-black mb-0 fz-16"><b>${{proposal.hourly_rate}}.00</b> /Day</h5> -->
                </b-col>
                <b-col xl="3" lg="3" md="3" sm="12" cols="6" v-if="($store.state.mobileWindow == true && proposal.totalEarn > 0)||($store.state.mobileWindow == false)">
                <h5 class="text-black mb-0 fz-16" v-if="proposal.totalEarn >0"><b>${{proposal.totalEarn}}</b> earned</h5>
                </b-col>
                <b-col xl="3" lg="3" md="3" sm="12" cols="6" v-if="($store.state.mobileWindow == true && proposal.jobSuccessRate > 0)||($store.state.mobileWindow == false)">
                <h5 class="text-black mb-0 fz-16" v-if="proposal.jobSuccessRate > 0"><b>{{proposal.jobSuccessRate}}%</b> Job Success</h5>
                </b-col>
                <b-col xl="3" lg="3" md="3" sm="12" cols="6" v-if="($store.state.mobileWindow == true)||($store.state.mobileWindow == false) && proposal.talent_id > 0" class="pt-0">
                  <TalentBage :talentQualilty="talentQualilty" :talent_id="proposal.talent_id"/>
                </b-col>
              </b-row>

              <!-- <p class="text-black fz-16 fw-500 pl-30">Cover Letter -  -->
                <!-- <vue-read-more-smooth :lines="2" class="mt-2">
                  <span class="fz-16 text-muted">{{proposal.cover_letter}}</span>
                </vue-read-more-smooth> -->
                <template v-if="proposal.cover_letter">
                  <!-- <read-more  more-str="Read more" :text="proposal.cover_letter" link="#" less-str="Read less" :max-chars="250"></read-more> -->
                  <p class="search-description fz-16 text-muted mb-0" v-html="(proposal.cover_letter)"> </p>
                </template>
              <!-- </p> -->

              <b-row class="mt-0" v-if="proposal.skillNameCertificate.length > 0">
                <b-col xl="12" lg="12" md="12" sm="12" class="col-12"  :class="'ali-'+proposal.skillNameCertificate.length" v-if="proposal.skillNameCertificate.length > 0">
                      <div class="skills" :id="'certificate-'+id">
                        <template v-for="(certificate,certi_index) in proposal.skillNameCertificate">
                          <span class="skill" :key="certi_index" v-if="certificate" :class="'certificate-'+id" :id="'certificate-'+id+'-'+certi_index" >{{(!checkcertificate('certificate',id,certi_index,proposal.skillNameCertificate.length))?'':certificate.name}}</span>
                        </template>
                          <span class="cursor-pointer skill" :id="'certificate-append-'+id" :class="'certificate-append-'+id" style="display:none;" v-b-toggle.sidebar-message @click="messageModel(proposal)"></span>
                      </div>
                    </b-col>
              </b-row>
              <b-row v-if="proposal.agency_id > 0">
                <b-col xl="6" lg="6" sm="12"  >
                  <div class="d-flex align-items-center border br-20 p-2" >
                    <img :src="proposal.agencyData.profile_picture_path" alt="" class="rounded-md w-10 h-10">
                    <div class="ml-2">
                      <h6 class="text-black fw-600 mb-1">Apply with Agency</h6>
                      <p class="mb-0 fz-14 text-muted">{{proposal.agencyData.first_name}}</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="text-center py-4 text-theme" v-if="loaderStatus">
            <b-spinner type="border" medium></b-spinner>
          </div>
          <div class="text-center  px-4 py-4" v-else>
            <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
              <path d="M65.7535 6.47396C70.8988 2.90571 77.7184 2.90571 82.8637 6.47396C86.1714 8.76789 90.2618 9.63733 94.2166 8.88711C100.368 7.72012 106.598 10.4939 109.848 15.8464C111.936 19.2874 115.319 21.7454 119.237 22.6686C125.332 24.1047 129.895 29.1726 130.686 35.3839C131.195 39.377 133.286 42.9986 136.49 45.4356C141.473 49.2264 143.581 55.7122 141.777 61.7083C140.618 65.563 141.055 69.7219 142.99 73.2513C146.001 78.7414 145.288 85.5236 141.202 90.2677C138.575 93.3176 137.282 97.2947 137.615 101.306C138.133 107.546 134.723 113.452 129.06 116.124C125.42 117.842 122.621 120.949 121.294 124.749C119.228 130.661 113.711 134.669 107.451 134.807C103.427 134.895 99.6067 136.596 96.8481 139.527C92.5572 144.087 85.8866 145.505 80.1119 143.085C76.3995 141.529 72.2177 141.529 68.5053 143.085C62.7306 145.505 56.06 144.087 51.769 139.527C49.0105 136.596 45.1902 134.895 41.1659 134.807C34.9059 134.669 29.3887 130.661 27.3235 124.749C25.9958 120.949 23.1976 117.842 19.5571 116.124C13.8943 113.452 10.4845 107.546 11.0021 101.306C11.3348 97.2947 10.0425 93.3176 7.41543 90.2677C3.3289 85.5236 2.61606 78.7414 5.62693 73.2513C7.56254 69.7219 7.99966 65.563 6.84015 61.7083C5.03652 55.7122 7.1439 49.2264 12.1275 45.4356C15.3313 42.9986 17.4222 39.377 17.9308 35.3839C18.7219 29.1726 23.2851 24.1047 29.3797 22.6686C33.2977 21.7454 36.6809 19.2874 38.7697 15.8464C42.0188 10.4939 48.2488 7.72012 54.4005 8.88711C58.3554 9.63733 62.4458 8.76789 65.7535 6.47396Z" fill="#F2613C"/>
              <path d="M68.4151 83.8327H76.452L83.8639 55.4354L86.4536 44.1836H74.2195L72.5228 55.4354L68.4151 83.8327ZM68.5044 105.086C73.6837 105.086 77.2557 100.621 77.2557 96.1561C77.2557 92.3162 74.7553 89.4586 70.9154 89.4586C65.7361 89.4586 62.1641 94.1022 62.1641 98.4779C62.1641 102.407 64.6645 105.086 68.5044 105.086Z" fill="white"/>
            </svg>
            <h4 class="fz-20 fw-600 text-black">You don't have any Offers yet</h4>
            <p class="fz-14 fw-500 text-black mb-0">Interview promising candidates and make them an offer.</p>
          </div>
        </template>
      
      <MessageDetails v-if="modelopenclose" ref="handleMessageDetailspage" :jobDetails='jobDetails' :jobId="jobId" :messageData="messageData" :pageType="'sidebar'" :pageName="'offers'" @getProposalData="getProposalData" @applicationStatus="updatecountStatus" @modelopenclose="modelopenclose=false"/>

      </b-col>
    </b-row>
  </div>
</template>
<script>
  import  MessageDetails from "./MessageDetails.vue";
  import { mapActions } from "vuex";
  import TalentBage from "../../../components/common/TalentBage.vue";
  // import {SliderFrame, SliderSlides, SliderSlide} from 'vue-dynamic-slider';

  // import VueReadMoreSmooth from "vue-read-more-smooth";
  export default {
    components : {
      MessageDetails,
      TalentBage,
      // SliderSlide, SliderSlides, SliderFrame,
      // VueReadMoreSmooth
    },
    props:['jobId','jobDetails','talentQualilty'],
    data() {
      return {
        proposalData:[],
        sliderScrollData:[],
        messageData:{
          userDetails:'',
          proposalDetails:'',
          education:'',
          similarjobHistory:'',
          jobUnderprocess:'',
          employmentData:[],
          jobHistory:[],
          favorite:'',
          noteData:{
            note:'',
            skill:[],
            tags:[],
          },
        },
        modelopenclose:false,
        loaderStatus: true,
        sliderScrollDataa:[],
      } 
    },
    computed:{
  
    },
    mounted(){
      this.getProposalData();
    },
    methods: {
      ...mapActions(["genericAPIPOSTRequest","UpdateCurrentUrl"]),
       // this fun is for to check certificate
       checkcertificate(classId,pid,cid,_t){
        return setTimeout(() => {
          var maxWidth = document.getElementById(classId+'-'+pid).offsetWidth;
          var certificateListWidth = 40;
          document.querySelectorAll('.'+classId+'-'+pid).forEach(element => {
            certificateListWidth += parseInt(element.offsetWidth)+parseInt(20);
            if(parseInt(maxWidth) < parseInt(certificateListWidth)){
              element.remove();
            }
          });
          if(_t == cid+1){
            var renderCount = document.querySelectorAll('.'+classId+'-'+pid).length;
            // console.log('renderCount',_t,renderCount)
            if(_t > renderCount){
              document.getElementById(classId+'-append-'+pid).textContent = '+'+(_t-renderCount);
              document.getElementById(classId+'-append-'+pid).style.display = 'inline-block';
              // document.getElementById(classId+'-append-'+pid).insertAdjacentHTML('beforeend', '<span id="'+classId+'-'+pid+'-'+(renderCount+1)+'" class="skill">+'+(_t-renderCount)+'</span>');
            }else{
              document.getElementById(classId+'-append-'+pid).style.display = 'none';
            }
          }
          return true;
        }, 100);
      },
      // this fun is for slide skills
      SlideSkill(id,direction,_i){
          var slider_width = document.querySelector('#'+id).parentElement.scrollWidth;
        var main_width = document.querySelector('#'+id).scrollWidth;
        if(direction =='right'){
          if(this.sliderScrollData[_i] > 0){
            this.sliderScrollData[_i] = this.sliderScrollData[_i]+parseInt(slider_width);
          }else{
            this.sliderScrollData[_i] = parseInt(slider_width);
          }
          if(main_width > this.sliderScrollData[_i]){
            const conent = document.querySelector('#'+id);
            conent.scrollLeft += slider_width;
            if(this.sliderScrollData[_i] == main_width){
              document.getElementById('next_'+_i).style.display = 'none';
            }
            document.getElementById('prev_'+_i).style.display = 'block';
          }else if(this.sliderScrollData[_i] >= main_width){
            this.sliderScrollData[_i] = this.sliderScrollData[_i]-parseInt(slider_width);
            document.getElementById('next_'+_i).style.display = 'none';
          }
        }else{
          // currentSlide_position-=slider_width
          this.sliderScrollData[_i] = this.sliderScrollData[_i]-parseInt(slider_width);
          if(this.sliderScrollData[_i] >= 0){
            const conent = document.querySelector('#'+id);
            conent.scrollLeft -= slider_width;
            if(conent.scrollLeft==0){
              document.getElementById('prev_'+_i).style.display = 'none';
            }
            document.getElementById('next_'+_i).style.display = 'block';
          }else if(this.sliderScrollData[_i] < 0){
            document.getElementById('prev_'+_i).style.display = 'none';
            this.sliderScrollData[_i] = this.sliderScrollData[_i]+parseInt(slider_width);
          }
          
        }
        
      },
      async messageModel(_v){
        // proposal.user_id,proposal.uniq_id
        this.modelopenclose=true;
        this.job_id=_v.uniq_id;
        var userDetails = await this.genericAPIPOSTRequest({
            requestUrl: "user/getUserDetails",
            params: {"user_id":'ASS'+this.encodedid(_v.user_id)},
        });
        if(userDetails){
          this.messageData.userDetails = userDetails;
          this.$refs.handleMessageDetailspage.getMilestoneData();
          this.$refs.handleMessageDetailspage.getChatlist();
          this.$refs.handleMessageDetailspage.getUserCertificationList();

        }
        var proposalDetails = await this.genericAPIPOSTRequest({
            requestUrl: "getProposalDetail",
            params: {"user_id":_v.user_id,"job_id":this.jobId},
        });
        if(proposalDetails){
          this.messageData.proposalDetails = proposalDetails;
        }
        var similarjobHistory = await this.genericAPIPOSTRequest({
            requestUrl: "getJobdataList",
            params: {"user_id":_v.user_id,"filter":6,search:{category:_v.skill_id}},
        });
        if(similarjobHistory){
          this.messageData.similarjobHistory = similarjobHistory;  
        }
        var jobUnderprocess = await this.genericAPIPOSTRequest({
            requestUrl: "getJobdataList",
            params: {"user_id":_v.user_id,"filter":2},
        });
        if(jobUnderprocess){
          this.messageData.jobUnderprocess = jobUnderprocess;
        }
        var jobHistory = await this.genericAPIPOSTRequest({
            requestUrl: "getJobdataList",
            params: {"user_id":_v.user_id,"filter":6},
        });
        if(jobHistory){
          this.messageData.jobHistory = jobHistory;  
        }
        var education = await this.genericAPIPOSTRequest({
          requestUrl: "user/getUserEducation",
          params: { "user_id" :_v.user_id }
        });
        if(education){
          this.messageData.education = education;
        }
        var employmentData = await this.genericAPIPOSTRequest({
            requestUrl: "user/getWorkExprince",
            params: {user_id: _v.user_id},
        }); 
        if(employmentData){
          this.messageData.employmentData = employmentData;
        }
        var favorite = await this.genericAPIPOSTRequest({
          requestUrl: "getfavoritelist",
          params: { "favorite_user_id" :_v.user_id }
        });
        if(favorite){
          this.messageData.favorite = favorite;
        }
        var note = await this.genericAPIPOSTRequest({
            requestUrl: "getNote",
            params: {'to_user_id': _v.user_id}
        });
        if(note){
            this.messageData.noteData = note;
        }
        if(this.getcurrentUrl == 'index'){
          this.UpdateCurrentUrl({params:'messageSideBar'})
        }
      },
      updatecountStatus(){
        this.$emit('applicationStatus1');
      },
      //this fun is for get proposal list
      async getProposalData(){
        this.loaderStatus= true;
        var res = await this.genericAPIPOSTRequest({
            requestUrl: "getjobApplicantList",
            params: { "jobsid": this.jobId, "filter": "7" }
        });
        if(res.length > 0){
          this.proposalData = res;
          this.loaderStatus= false;
        }else{
          this.loaderStatus= false;
        } 
      },
      // toggleDropdown (event) {
      //   event.currentTarget.classList.toggle('is-active')
      // }
    }
  }
</script>

