<template>
    <b-container>
        <b-card-text>
            <b-row class="milestone-timeline-wrap mt-100 contract-details">
                <b-col lg="12" xl="12" sm="12">
                    <b-card-group deck>
                    <b-card class="py-4 py-sm-2 px-4 px-sm-0 bg-white br-20">
                        <div class="d-md-flex align-items-center text-left mb-4 pro-card">
                            <div class="img-wrapper mr-3 pos-rel">
                                <img :src="contractDetails.userDetails.client_profile" class="img-fluid">
                                <span class="online" :class="(checkUserOnlineOrOffline(contractDetails.userDetails) == true) ? 'bg-success' : ''"></span>
                            </div>
                            <div class="content ml-2">
                                <h4 class="fz-22 fw-600 text-black mr-2 mb-0">{{contractDetails.userDetails.company_name}}</h4>
                                <p class="fz-16 fw-500 text-muted mb-0" v-if="contractDetails.userDetails.country_name != null">{{contractDetails.userDetails.country_name+', '+contractDetails.userDetails.state_name+', '+contractDetails.userDetails.city_name}}</p>
                            </div>
                            <div class="ml-auto d-flex align-items-center" v-if="$store.state.accountType == 'agency' || ($store.state.accountType == 'freelancer' && contractDetails.proposalDetails.agency_id == 0)">
                                <b-dropdown size="md" right class="mr-2 mt-0 t-0"
                                variant="outline-secondary" toggle-class="text-decoration-none"
                                no-caret>
                                    <template #button-content>
                                        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                    </template>
                                    <!-- v-if="contractDetails.proposalDetails.application_status == 6 &&  contractDetails.proposalDetails.contract_ended_by == contractDetails.proposalDetails.current_user_id && feedbackRequest =='' && contractDetails.checkfeedbackgiven.length ==0"
                                    v-if="contractDetails.proposalDetails.application_status == 6 && contractDetails.proposalDetails.contract_ended_by != contractDetails.proposalDetails.current_user_id && contractDetails.checkfeedbackgiven.length == 0"
                                    v-if="(contractDetails.proposalDetails.application_status == 2 || contractDetails.proposalDetails.application_status == 6) && !checkcurrentuserReview" -->
                                    <b-dropdown-item href="javascript:void(0);" @click="requestForfeedback" v-if="contractDetails.proposalDetails.application_status == 6 &&  contractDetails.proposalDetails.contract_ended_by == $store.state.currentUserData.login_master_id && contractDetails.checkfeedbackgiven.length == 1">
                                        <div class="d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-journals mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                                            <path
                                                d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                                        </svg>
                                        Request For Feedback
                                        </div>
                                    </b-dropdown-item>
                                    <b-dropdown-item href="javascript:void(0);" @click="$router.push({name:'endContract'+$store.state.accountType, path:'/'+$store.state.accountType+'/end-contract',params:{pid:job_id,uid:'A'+encodedid(freelancer_id)}}).catch(()=>{})" v-if="contractDetails.proposalDetails.application_status == 6 && contractDetails.proposalDetails.contract_ended_by != $store.state.currentUserData.login_master_id  ">
                                    <!-- <b-dropdown-item href="javascript:void(0);" @click="requestForfeedback" v-if="contractDetails.proposalDetails.application_status == 6 && feedbackRequest == ''"> -->
                                        <div class="d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-journals mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                                            <path
                                                d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                                        </svg>
                                        Send Feedback
                                        </div>
                                    </b-dropdown-item>
                                    <b-dropdown-item href="javascript:void(0);" v-b-modal.end-contract v-if="contractDetails.proposalDetails.application_status == 2 ">
                                        <div class="d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-journals mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                                            <path
                                                d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                                        </svg>
                                        End Contract
                                        </div>
                                    </b-dropdown-item>
                                    
                                </b-dropdown>
                            </div>
                        </div>
                        

                        <h4 class="fz-22 fw-600 text-black mr-2 mb-0 pro-title">{{contractDetails.jobDetails.job_title}}</h4>
                        <div class="d-md-flex align-items-center text-left">
                            <p class="fz-16 fw-500 text-muted mb-0" v-if="contractDetails.proposalDetails.application_status == 6">Completed on {{contractDetails.proposalDetails.ended_date}}</p><br>
                            <div class="star-rating mb-0 ml-2 text-theme" v-if="contractDetails.proposalDetails.application_status == 6  && contractDetails.checkfeedbackgiven.length == 2">
                                <label for="5-stars" class="star " :class="(checkClientReview >= 1)?'text-theme':''">
                                <i class="fa fa-star"></i>
                                </label>
                                <label for="4-stars" class="star " :class="(checkClientReview >= 2)?'text-theme':''">
                                <i class="fa fa-star"></i>
                                </label>
                                <label for="3-stars" class="star" :class="(checkClientReview >= 3)?'text-theme':''">
                                <i class="fa fa-star"></i>
                                </label>
                                <label for="2-stars" class="star" :class="(checkClientReview >= 4)?'text-theme':''">
                                <i class="fa fa-star"></i>
                                </label>
                                <label for="1-star" class="star" :class="(checkClientReview == 5)?'text-theme':''">
                                <i class="fa fa-star"></i>
                                </label>
                            </div>
                        </div>
                    </b-card>
                    </b-card-group>
                </b-col>
                <b-col lg="12" xl="12" sm="12" v-if="contractDetails.proposalDetails.refund_request_status == 1 && contractDetails.proposalDetails.application_status == 6">
                    <b-alert show variant="warning" class="d-flex align-items-center">
                        <i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
                        {{contractDetails.userDetails.company_name}} has requested a refund on {{ contractDetails.proposalDetails.ended_date_mdy }}.<span class="cursor-pointer text-theme" @click="$router.push({'name': $store.state.accountType+'RefundRequest','path' : $store.state.accountType+'/escrow-refund-request','params': {'pid': $route.params.pid,'uid': $route.params.uid}}).catch(()=>{})"> Respond to request</span>
                    </b-alert>
                </b-col>
                <b-col lg="12" xl="12" sm="12">
                    <b-tabs v-model="currentPage" content-class="mt-4">
                        <b-tab title="Overview" :active="currentPage === 0" @click="changeCurrentPage(0)">
                            <b-row v-if="feedbackRequest!='' && contractDetails.checkfeedbackgiven.length == 0">
                                <b-col lg="12" xl="12" sm="12">
                                    <div class="feedback-comment-wrapper border shadow bg-white p-2">
                                        <p class="mb-0 fz-16 d-flex align-items-center fw-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f2613d" class="bi bi-star-fill mr-2" viewBox="0 0 16 16">
                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                            {{shortName(contractDetails.userDetails.first_name,contractDetails.userDetails.last_name)}} has requested feedback on this contract. <a href="javascript:void(0);" @click="$router.push({name:'endContract'+$store.state.accountType, path:'/'+$store.state.accountType+'/end-contract',params:{pid:job_id,uid:'A'+encodedid(freelancer_id)}}).catch(()=>{})" class="text-theme ml-2">Give feedback</a>
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="9" xl="9" sm="12">
                                    <b-card-group deck>
                                    <b-card class="py-4 px-4 bg-white br-20 mt-2">
                                        <h4 class="fz-20 fw-600 text-black mr-2 mb-4">Milestone Timeline</h4>
                                        <div class="alert-wrapper p-3 br-10 bg-grey mb-4" v-if="checkMilestoneIspending()">
                                                <h6 class="mb-0" >
                                                    <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                                                     You requested milestone changes 
                                                     <a href="javascript:void(0);" @click="manageMilestoneData();" v-b-toggle.sidebar-backdrop class="text-theme">View Changes</a>
                                                </h6>
                                            </div>
                                        <div class="timeline-container">
                                            <span v-for="(milestone,index) in contractDetails.MilestoneDetails" :key="index">
                                                <div class="timeline-block timeline-block-right"  v-if="milestone.status!=0 && milestone.active == 1">
                                                    <div class="marker" v-if="milestone.milestone_status == 0">{{index+1}}</div>

                                                    <div class="marker active" v-else><i class="fa fa-check active" aria-hidden="true"></i></div>

                                                    <div class="timeline-content">
                                                    <!-- <h3 class="mb-2 fz-18 text-black">First 15 days</h3> -->
                                                    <p class="mb-3 fz-16 text-black">{{milestone.description}}</p>
                                                    <h3 class="fw-600 fz-20 text-black mb-3">${{ (milestone.release_amount==0)?milestone.amount:milestone.release_amount }}
                                                        <span class="badge badge-theme fz-14 fw-600 br-20" >{{ StatusLabel(milestone)}}</span>
                                                    </h3>
                                                    <p class="fz-16 text-muted fw-600 d-flex align-items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3 mr-2" viewBox="0 0 16 16">
                                                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                                                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                                        </svg>
                                                        {{milestone.dueDates}}
                                                    </p>
                                                    <template v-if="milestone.milestone_status == 0 && !ContractEnded">
                                                        <div class="submission-qa mb-4" v-for="(subWork,index) in milestone.submittedWork" :key="index">
                                                            <div class="d-flex align-items-normal mb-3" v-b-toggle.review-sidebar-modal v-on:click="reviewWork = milestone">
                                                                <div class="text-muted mt-2 ml-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-check" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                                                                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                                                                    </svg>
                                                                </div>
                                                                <span class="flex-1 ml-2">
                                                                    <button class="d-block mb-0 text-left text-black fw-600"> Work submitted for review </button>
                                                                    <small>
                                                                        <span class="text-muted date-created">{{subWork.createdDate}}</span>
                                                                    </small>
                                                                </span>
                                                            </div>
                                                            <div class="d-flex align-items-normal mb-3" v-b-toggle.review-sidebar-modal v-if="subWork.status == 3" v-on:click="reviewWork = milestone">
                                                                <div class="text-muted mt-2 ml-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-check" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M0 11.044V14h2.956l8.555-8.633L8.556 2.41 0 11.044zm13.767-7.933a.752.752 0 000-1.089L11.977.233a.752.752 0 00-1.088 0l-1.4 1.4 2.955 2.956 1.323-1.478z"></path>
                                                                    </svg>
                                                                </div>
                                                                <span class="flex-1 ml-2">
                                                                    <button class="d-block mb-0 text-left text-black fw-600"> Revision requested </button>
                                                                    <small>
                                                                        <span class="text-muted date-created">{{subWork.updatedDate}}</span>
                                                                    </small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <p class="cursor-pointer text-theme" v-if="milestone.milestone_status == 1 && ($store.state.accountType == 'freelancer' && milestone.agency_id == 0 || $store.state.accountType == 'agency') " 
                                                             @click="getinvoiceData(milestone)"><i class="fa fa-download" aria-hidden="true"></i> {{'Invoice'}}</p>
                                                    </template>
                                                    <button class="btn btn-theme" @click="submitWorkmodal(milestone)" v-if="milestone.paymentStatus == 1 && milestone.milestone_status == 0 && !ContractEnded">Submit {{(milestone.submittedWork.length > 0)? 'Revision':'Work'}}</button>
                                                    <!-- <button class="btn btn-theme" v-b-modal.submitWork-modal @click="submitWorkmodal(milestone)" v-if="(milestone.status == null || milestone.status == 3) && milestone.submitworkBtn == 1">Submit {{(milestone.submittedWork.length > 0)? 'Revision':'Work'}}</button> -->
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="timeline-block timeline-block-left" v-if="contractDetails.proposalDetails.application_status != 6 && !ContractEnded">
                                            <div class="marker activated disabled text-white" >
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </div>
                                            <div class="timeline-content">
                                                <a class="btn btn-theme text-theme" href="javascript:void(0)" v-b-toggle.sidebar-backdrop v-b-Modal.add-milestone-modal v-if="!checkMilestoneIspending()" @click="manageMilestoneData()">Add new milestone</a>
                                                <a class="text-theme disabled" href="javascript:void(0)" v-else><h3 class="mb-2 fz-18 text-black">Add new milestone</h3></a>
                                            </div>
                                        </div>
                                        <button v-b-toggle.sidebar-backdrop class="btn-theme mt-4" v-if="!checkMilestoneIspending() && contractDetails.proposalDetails.application_status != 6 && !ContractEnded" @click="manageMilestoneData()" >Manage Milestones</button>
                                        <button class="btn-theme mt-4 disabled" v-else >Contract Ended</button>
                                    </b-card>
                                    </b-card-group>
                                </b-col>
                                <b-col lg="3" xl="3" sm="12">
                                    <b-card-group deck>
                                        <b-card class="py-4 px-3 bg-white br-20">
                                            <h4 class="fz-20 fw-600 text-black mr-2 mb-3">Billings</h4>
                                            <b-progress  :max="totalamount" variant="theme" class="mb-3 mw-100" show-progress>
                                                <b-progress-bar :value="ReleaseFund" :label="`${((ReleaseFund / totalamount) * 100).toFixed(2)}%`"></b-progress-bar>
                                            </b-progress>
                                            <div class="payout-wrap">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="fz-16 fw-600 text-black"><i
                                                            class="fa fa-circle text-theme"></i> Paid Out</span>
                                                    <span class="ml-auto fz-16 fw-600 text-black">${{ReleaseFund}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="fz-16 fw-600 text-black"><i
                                                            class="fa fa-circle text-success"></i> Funded (Escrow
                                                        Protection)</span>
                                                    <span class="ml-auto fz-16 fw-600 text-black">${{ escrowAmount }}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-2" v-if="refundedescrowAmount > 0">
                                                    <span class="fz-16 fw-600 text-black"><i
                                                            class="fa fa-circle text-warning"></i> Refunded (Escrow
                                                        Protection)</span>
                                                    <span class="ml-auto fz-16 fw-600 text-black">${{ refundedescrowAmount }}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="fz-16 fw-600 text-black"><i
                                                            class="fa fa-circle text-secondary"></i> Project Price</span>
                                                    <span class="ml-auto fz-16 fw-600 text-black">${{ totalamount }}</span>
                                                </div>
                                            </div>
                                            <!-- <hr> -->
                                            <!-- <b-button class="btn btn-outline-theme w-100">Rehire Pavan</b-button> -->
                                        </b-card>
                                    </b-card-group>
                                </b-col>
                            </b-row>
                            <Recentfiles :contractDetails="contractDetails" @refreshRecentFiles="getRecentfileData" />
                            <!-- // this is submit work model -->
                            <SubmitWork v-if="showSubmitworkModal" :submitWorkPayment="submitWorkPayment" :contractDetails="contractDetails" :chatroomdata="chatroomdata" @refreshSubmittedwork="updatedMilestone" @CloseModal="showSubmitworkModal = false" />
                            <!-- this is for to review work -->
                            <ReviewWorkVue :contractDetails="contractDetails" :reviewWork="reviewWork"/>
                            <!-- add-milestone-sidebar -->
                            <ManageMilestone ref="manageMilestoneFun" :chatroomdata="chatroomdata" :sidebarMilestoneData.sync="sidebarDetails" :contractDetails="contractDetails" @manageParentController="updatedMilestone"/>
                            
                            <b-modal id="end-contract" size="lg" title="End Contract">
                                <div class="text-center">
                                    <svg width="151" height="151" viewBox="0 0 151 151" fill="none" xmlns="http://www.w3.org/2000/svg" class="mb-2">
                                    <path d="M66.3199 8.25326C71.4652 4.68501 78.2848 4.68501 83.4301 8.25326C86.7378 10.5472 90.8282 11.4166 94.7831 10.6664C100.935 9.49942 107.165 12.2732 110.414 17.6257C112.503 21.0667 115.886 23.5247 119.804 24.4479C125.898 25.884 130.462 30.9519 131.253 37.1632C131.761 41.1563 133.852 44.7779 137.056 47.2149C142.04 51.0057 144.147 57.4915 142.343 63.4876C141.184 67.3423 141.621 71.5012 143.557 75.0306C146.568 80.5207 145.855 87.3029 141.768 92.047C139.141 95.0969 137.849 99.074 138.182 103.086C138.699 109.326 135.289 115.232 129.626 117.903C125.986 119.621 123.188 122.729 121.86 126.529C119.795 132.44 114.278 136.448 108.018 136.586C103.993 136.674 100.173 138.375 97.4145 141.307C93.1236 145.867 86.453 147.285 80.6783 144.864C76.9659 143.308 72.7841 143.308 69.0717 144.864C63.297 147.285 56.6264 145.867 52.3355 141.307C49.5769 138.375 45.7566 136.674 41.7323 136.586C35.4723 136.448 29.9551 132.44 27.8899 126.529C26.5622 122.729 23.764 119.621 20.1235 117.903C14.4607 115.232 11.0509 109.326 11.5685 103.086C11.9012 99.074 10.609 95.0969 7.98184 92.047C3.89531 87.3029 3.18246 80.5207 6.19334 75.0306C8.12895 71.5012 8.56607 67.3423 7.40656 63.4876C5.60293 57.4915 7.7103 51.0057 12.6939 47.2149C15.8977 44.7779 17.9886 41.1563 18.4972 37.1632C19.2883 30.9519 23.8515 25.884 29.9461 24.4479C33.8642 23.5247 37.2473 21.0667 39.3361 17.6257C42.5852 12.2732 48.8152 9.49942 54.9669 10.6664C58.9218 11.4166 63.0122 10.5472 66.3199 8.25326Z" fill="#F2613C"/>
                                    <path d="M68.9854 85.612H77.0223L84.4342 57.2147L87.0239 45.9629H74.7899L73.0932 57.2147L68.9854 85.612ZM69.0747 106.865C74.2541 106.865 77.826 102.4 77.826 97.9354C77.826 94.0955 75.3257 91.2379 71.4858 91.2379C66.3064 91.2379 62.7344 95.8815 62.7344 100.257C62.7344 104.186 65.2348 106.865 69.0747 106.865Z" fill="white"/>
                                    <path d="M133.023 19.7399C133.472 19.7615 133.931 19.6116 134.29 19.2849L149.692 5.29456C150.411 4.64135 150.465 3.5288 149.811 2.8099C149.158 2.09076 148.046 2.03733 147.327 2.69055L131.925 16.6809C131.206 17.3341 131.152 18.4466 131.805 19.1655C132.132 19.525 132.573 19.7183 133.023 19.7399Z" fill="#F2613C"/>
                                    <path d="M149.113 26.9153C148.903 25.9669 147.964 25.3686 147.015 25.5792L139.571 27.2309C138.622 27.4413 138.024 28.3806 138.235 29.3291C138.41 30.1206 139.094 30.6684 139.866 30.7055C140.019 30.7128 140.176 30.7002 140.333 30.6652L147.777 29.0135C148.726 28.8033 149.324 27.8637 149.113 26.9153Z" fill="#F2613C"/>
                                    <path d="M122.151 11.7905C122.304 11.8404 122.458 11.868 122.612 11.8754C123.384 11.9125 124.117 11.4327 124.368 10.6617L126.725 3.40987C127.026 2.48599 126.52 1.49336 125.597 1.19328C124.673 0.892255 123.68 1.39827 123.38 2.32215L121.022 9.57397C120.722 10.4978 121.227 11.4902 122.151 11.7905Z" fill="#F2613C"/>
                                    </svg>
                                    <h4>Are you sure you want to end this contract?</h4>
                                    <p>You'll be promoted to provide feedback and make any final payment in the following steps.</p>
                                </div>
                                <template #modal-footer>
                                    <div class="w-100 text-right">
                                        <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('end-contract')">Cancel</a>
                                    <b-button 
                                        size="lg"
                                        class="btn btn-theme"
                                        @click="$router.push({name:'endContract'+$store.state.accountType, path:'/'+$store.state.accountType+'/end-contract',params:{pid:job_id,uid:'A'+encodedid(freelancer_id)}}).catch(()=>{})"
                                    >
                                        End Contract
                                    </b-button>
                                    </div>
                                </template>
                            </b-modal>
                        </b-tab>
                        <b-tab title="Messages" :active="currentPage === 1" @click="changeCurrentPage(1)">
                            <b-row v-if="feedbackRequest!='' && contractDetails.checkfeedbackgiven.length == 0">
                                <b-col lg="12" xl="12" sm="12">
                                    <div class="feedback-comment-wrapper border shadow bg-white p-2">
                                        <p class="mb-0 fz-16 d-flex align-items-center fw-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f2613d" class="bi bi-star-fill mr-2" viewBox="0 0 16 16">
                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                            {{shortName(contractDetails.userDetails.first_name,contractDetails.userDetails.last_name)}} has requested feedback on this contract. <a href="javascript:void(0);" @click="$router.push({name:'endContract'+$store.state.accountType,path:'/'+$store.state.accountType+'/end-contract',params:{pid:job_id,uid:'A'+encodedid(freelancer_id)}}).catch(()=>{})" class="text-theme ml-2">Give feedback</a>
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" xl="12" sm="12">
                                    <b-card-group deck>
                                        <b-card class="py-4 px-4 bg-white br-20 mt-2">
                                            <b-col xl="12" lg="12" sm="12" class="conversation" v-if="!messageSectionShow">
                                                <div class="overlay-msg">
                                                    <div>
                                                        <h5>Private Room</h5>
                                                        <h4><b>{{shortName(contractDetails.userDetails.first_name,contractDetails.userDetails.last_name)}}</b></h4><hr>
                                                        <h6 class="fz-18 fw-600 pb-4">Participants</h6>
                                                        <div class="d-flex align-items-center inner-div pb-1" v-for="(member,index) in chatroomMembers" :key="index">
                                                            <div class="avtar-icon">
                                                                <img :src="member.profile_picture_path" class="img-fluid">
                                                                <span class="status"></span>
                                                            </div>
                                                            <div class="text-content ml-2">
                                                                <h5 class="mb-0">{{shortName(member.first_name,member.last_name)}}</h5>
                                                            </div>
                                                        </div>
                                                        <b-button class="btn btn-theme mt-4" v-if="chatroomId!=''" @click="joinRoom()">Join the Room</b-button>
                                                    </div>
                                                </div>
                                            </b-col>
                                            <b-col xl="12" lg="12" sm="12" class="conversation pos-rel" v-else-if="messageSectionShow">
                                                <b-row class="heading" v-if="($store.state.accountType == 'agency' || $store.state.accountType == 'freelancer')  && chatroomdata!=''">
                                                    <b-col xl="12" lg="12" sm="12" class="py-1">
                                                        <div class="heading-avatar d-flex align-items-center">
                                                            <div class="width-98 d-flex align-items-center">
                                                                <div class="heading-name">
                                                                    <a class="heading-name-meta mb-0 pt-0 fz-18 fw-600">{{ contractDetails.userDetails.company_name }}</a>
                                                                    <p class="ml-1 mb-0 fz-14 text-black"><span class="heading-online text-muted mt-0 fz-14" >{{ chatroomdata.job_title }}</span></p>
                                                                    <!-- <p class="ml-1 mb-0 fz-14 text-black">12:09 AM EST <span class="heading-online text-muted mt-0 fz-14" @click="$router.push({'path':'client/jobpostreview','name':'index',params:{'pid':chatroomdata.uniq_id}}).catch(()=>{})">{{ chatroomdata.job_title }}</span></p> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                <chatBoxData ref="oprateChatbox" :roomlist="roomlist" :chatroomdata="chatroomdata" :chatroomMembers="chatroomMembers" :pageType="'contractpage'"  v-if="chatroomdata!=''" />
                                            </b-col>
                                        </b-card>
                                    </b-card-group>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <b-tab title="Details" :active="currentPage === 2" @click="changeCurrentPage(2)">
                            <b-row v-if="feedbackRequest!='' && contractDetails.checkfeedbackgiven.length == 0 && currentPage == 2">
                                <b-col lg="12" xl="12" sm="12">
                                    <div class="feedback-comment-wrapper border shadow bg-white p-2">
                                        <p class="mb-0 fz-16 d-flex align-items-center fw-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f2613d" class="bi bi-star-fill mr-2" viewBox="0 0 16 16">
                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                            {{shortName(contractDetails.userDetails.first_name,contractDetails.userDetails.last_name)}} has requested feedback on this contract. <a href="javascript:void(0);" @click="$router.push({name:'endContract'+$store.state.accountType ,path:'/'+$store.state.accountType+'/end-contract',params:{pid:job_id,uid:'A'+encodedid(freelancer_id)}}).catch(()=>{})" class="text-theme ml-2">Give feedback</a>
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="9" xl="9" sm="12">
                                    <b-card-group deck>
                                    <b-card class="py-4 px-4 bg-white br-20 mt-2">
                                        <h4 class="fz-20 fw-600 text-black mr-2 mb-4">Summary</h4>
                                        <div class="payout-wrap">
                                            <div class="d-flex align-items-center mb-2">
                                                <span class="fz-16 fw-600 text-black mr-5 width-40 d-block">Contact type</span>
                                                <span class="fz-16 fw-500 text-muted width-60 d-block">{{(contractDetails.jobDetails.budget_type =='A')? 'Day Wise':'Month Wise'}}</span>
                                            </div>
                                            <div class="d-flex align-items-center mb-2">
                                                <span class="fz-16 fw-600 text-black mr-5 width-40 d-block">Total spent</span>
                                                <span class="fz-16 fw-500 text-muted width-60 d-block">{{totalamount}}</span>
                                            </div>
                                            <div class="d-flex align-items-center mb-2">
                                                <span class="fz-16 fw-600 text-black mr-5 width-40 d-block">Start date</span>
                                                <span class="fz-16 fw-500 text-muted width-60 d-block">{{contractDetails.jobDetails.start_date}}</span>
                                            </div>
                                            <div class="d-flex align-items-center mb-2">
                                                <span class="fz-16 fw-600 text-black mr-5 width-40 d-block">Contact person</span>
                                                <span class="fz-16 fw-500 text-muted width-60 d-block">{{contractDetails.userDetails.company_name}}</span>
                                            </div>
                                            <b-button class="btn btn-outline-theme mt-4" @click="summeryDetails = true" v-if="!summeryDetails">Show Details</b-button>
                                        </div>
                                        <span   v-if="summeryDetails">
                                            <h4 class="fz-20 fw-600 text-black mr-2 mb-4">Details</h4>
                                            <div class="payout-wrap">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="fz-16 fw-600 text-black mr-5 width-40 d-block">Verified Name</span>
                                                    <span class="fz-16 fw-500 text-muted width-60 d-block">{{contractDetails.userDetails.company_name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="fz-16 fw-600 text-black mr-5 width-40 d-block">Hired By</span>
                                                    <span class="fz-16 fw-500 text-muted width-60 d-block">{{contractDetails.userDetails.company_name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="fz-16 fw-600 text-black mr-5 width-40 d-block">Contract Id</span>
                                                    <span class="fz-16 fw-500 text-muted d-block">{{contractDetails.proposalDetails.job_application_id}}</span>
                                                </div>
                                                <b-button class="btn btn-outline-theme mt-4 width-60" @click="summeryDetails = false" >Show Less</b-button>
                                            </div>
                                        </span>
                                    </b-card>
                                    </b-card-group>

                                    <b-card-group deck>
                                    <b-card class="py-4 px-4 bg-white br-20 mt-2">
                                        <h4 class="fz-20 fw-600 text-black mr-2 mb-4">Description</h4>
                                        <p v-html="getHtmlContent(contractDetails.jobDetails.scope_of_work)"></p>
                                        <!-- <a href="javascript:void(0);" class="text-theme d-block mb-0 cursor-pointer">View original job posting</a>
                                        <a href="javascript:void(0);" class="text-theme d-block mb-0 cursor-pointer">View original proposals</a> -->
                                    </b-card>
                                    </b-card-group>

                                    <b-card-group deck>
                                        <b-card class="py-4 px-4 bg-white br-20 mt-2">
                                            <h4 class="fz-20 fw-600 text-black mr-2 mb-4">Recent Activity</h4>
                                            <b-row>
                                                <b-col xl="3" lg="3" sm="3">
                                                    <strong>Date</strong>
                                                </b-col>
                                                <b-col xl="9" lg="9" sm="9">
                                                    <strong>Description</strong>
                                                </b-col>
                                            </b-row>
                                            <span v-for="(activities,index) in contractDetails.recentActivties" :key="index">
                                                <b-row v-if="index <= 3 && !showHideRecentActivity">
                                                    <b-col xl="3" lg="3" sm="3" v-if="activities.type == 'SENDOFFER' || activities.type == 'FUNDFIRSTMILESTONE' || activities.type == 'ACCEPTOFFER'|| activities.type == 'SUBMITWORK'|| activities.type == 'FUNDEDMILESTONE' || activities.type == 'REJECTEDWORK' || activities.type == 'ACCEPTWORK' || activities.type == 'RELEASEFUND'|| activities.type == 'ESCROWREFUNDED' || activities.type == 'WITHDRAWALOFFER' || activities.type == 'SUBMITPROPOSAL'">
                                                        {{ activities.date }}
                                                    </b-col>
                                                    <b-col xl="3" lg="3" sm="3" v-else-if="checkReview == 2 && (activities.type=='CONTRACTENDEDBYFREELANCER' || activities.type=='CONTRACTENDEDBYCLIENT' || activities.type=='CONTRACTENDEDBYAGENCY')">
                                                        {{ activities.date }}
                                                    </b-col>
                                                    <b-col xl="9" lg="9" sm="9">
                                                        <p class="mb-0" v-if="activities.type=='SENDOFFER'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='FUNDFIRSTMILESTONE'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='ACCEPTOFFER'">{{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}} {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='SUBMITWORK'">{{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}} {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='FUNDEDMILESTONE'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='REJECTEDWORK'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='ACCEPTWORK'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='RELEASEFUND'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='ESCROWREFUNDED'">{{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='WITHDRAWALOFFER'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='SUBMITPROPOSAL'">{{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="checkReview == 2 && activities.type=='CONTRACTENDEDBYFREELANCER'">
                                                            {{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}} gave {{(activities.client_name!='')?activities.client_name:''}} feedback.
                                                        </p>
                                                        <p class="mb-0" v-else-if="checkReview == 2 && activities.type=='CONTRACTENDEDBYAGENCY'">
                                                            {{(activities.agency_name!='')?activities.agency_name:''}} gave {{(activities.client_name!='')?activities.client_name:''}}  feedback.
                                                        </p>
                                                        <p class="mb-0" v-else-if="checkReview == 2 && activities.type=='CONTRACTENDEDBYCLIENT'">
                                                            {{(activities.client_name!='')?activities.client_name:''}} gave {{(activities.agency_name!='')?activities.agency_name:activities.freelancer_name}} {{(activities.agency_name!='' )?'':activities.freelancer_lastname}}  feedback.
                                                        </p>
                                                        <div class="star-rating mt-2 mb-2" v-if="checkReview == 2 && (activities.type=='CONTRACTENDEDBYFREELANCER' || activities.type=='CONTRACTENDEDBYCLIENT' || activities.type=='CONTRACTENDEDBYAGENCY')">
                                                            <label :class="(activities.rating >= 1)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                            <label :class="(activities.rating >= 2)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                            <label :class="(activities.rating >= 3)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                            <label :class="(activities.rating >= 4)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                            <label :class="(activities.rating == 5)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                        </div>
                                                        <p class="mb-0" v-if="checkReview == 2 && (activities.type=='CONTRACTENDEDBYFREELANCER' || activities.type=='CONTRACTENDEDBYCLIENT' || activities.type=='CONTRACTENDEDBYAGENCY')">{{activities.description}}</p>
                                                    </b-col>
                                                    <hr v-if="activities.type == 'SENDOFFER' || activities.type == 'FUNDFIRSTMILESTONE' || activities.type == 'ACCEPTOFFER'|| activities.type == 'SUBMITWORK'|| activities.type == 'FUNDEDMILESTONE' || activities.type == 'REJECTEDWORK' || activities.type == 'ACCEPTWORK' || activities.type == 'RELEASEFUND'|| activities.type == 'ESCROWREFUNDED' || activities.type == 'WITHDRAWALOFFER' || activities.type == 'SUBMITPROPOSAL'">
                                                    <hr v-else-if="checkReview == 2 && (activities.type=='CONTRACTENDEDBYFREELANCER' || activities.type=='CONTRACTENDEDBYCLIENT' || activities.type=='CONTRACTENDEDBYAGENCY')">
                                                </b-row>
                                                <b-row v-if="showHideRecentActivity">
                                                    <b-col xl="3" lg="3" sm="3" v-if="activities.type == 'SENDOFFER' || activities.type == 'FUNDFIRSTMILESTONE' || activities.type == 'ACCEPTOFFER'|| activities.type == 'SUBMITWORK'|| activities.type == 'FUNDEDMILESTONE' || activities.type == 'REJECTEDWORK' || activities.type == 'ACCEPTWORK' || activities.type == 'RELEASEFUND' || activities.type == 'ESCROWREFUNDED' || activities.type == 'WITHDRAWALOFFER' || activities.type == 'SUBMITPROPOSAL'">
                                                        {{ activities.date }}
                                                    </b-col>
                                                    <b-col xl="3" lg="3" sm="3" v-else-if="checkReview == 2 && (activities.type=='CONTRACTENDEDBYFREELANCER' || activities.type=='CONTRACTENDEDBYCLIENT' || activities.type=='CONTRACTENDEDBYAGENCY')">
                                                        {{ activities.date }}
                                                    </b-col>
                                                    <b-col xl="9" lg="9" sm="9">
                                                        <p class="mb-0" v-if="activities.type=='SENDOFFER'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='FUNDFIRSTMILESTONE'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='ACCEPTOFFER'">{{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}} {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='SUBMITWORK'">{{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}} {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='FUNDEDMILESTONE'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='REJECTEDWORK'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='ACCEPTWORK'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='RELEASEFUND'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='ESCROWREFUNDED'">{{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='WITHDRAWALOFFER'">{{(activities.client_name!='')?activities.client_name:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="activities.type=='SUBMITPROPOSAL'">{{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}}  {{activities.description}}</p>
                                                        <p class="mb-0" v-else-if="checkReview == 2 && activities.type=='CONTRACTENDEDBYFREELANCER'">
                                                            {{(activities.freelancer_name!='')?activities.freelancer_name:''}} {{(activities.freelancer_lastname!='')?activities.freelancer_lastname:''}} gave {{(activities.client_name!='')?activities.client_name:''}} feedback.
                                                        </p>
                                                        <p class="mb-0" v-else-if="checkReview == 2 && activities.type=='CONTRACTENDEDBYAGENCY'">
                                                            {{(activities.agency_name!='')?activities.agency_name:''}} gave {{(activities.client_name!='')?activities.client_name:''}}  feedback.
                                                        </p>
                                                        <p class="mb-0" v-else-if="checkReview == 2 && activities.type=='CONTRACTENDEDBYCLIENT'">
                                                            {{(activities.client_name!='')?activities.client_name:''}} gave {{(activities.agency_name!='')?activities.agency_name:activities.freelancer_name}} {{(activities.agency_name!='' )?'':activities.freelancer_lastname}}  feedback.
                                                        </p>
                                                        <div class="star-rating mt-2 mb-2" v-if="checkReview == 2 && (activities.type=='CONTRACTENDEDBYFREELANCER' || activities.type=='CONTRACTENDEDBYCLIENT' || activities.type=='CONTRACTENDEDBYAGENCY')">
                                                            <label :class="(activities.rating >= 1)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                            <label :class="(activities.rating >= 2)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                            <label :class="(activities.rating >= 3)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                            <label :class="(activities.rating >= 4)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                            <label :class="(activities.rating == 5)?'active':''"><i class="fa fa-star" aria-hidden="true"></i></label>
                                                        </div>
                                                        <p class="mb-0" v-if="checkReview == 2 && (activities.type=='CONTRACTENDEDBYFREELANCER' || activities.type=='CONTRACTENDEDBYCLIENT' || activities.type=='CONTRACTENDEDBYAGENCY')">{{activities.description}}</p>
                                                    </b-col>
                                                    <hr v-if="activities.type == 'SENDOFFER' || activities.type == 'FUNDFIRSTMILESTONE' || activities.type == 'ACCEPTOFFER'|| activities.type == 'SUBMITWORK'|| activities.type == 'FUNDEDMILESTONE' || activities.type == 'REJECTEDWORK' || activities.type == 'ACCEPTWORK' || activities.type == 'RELEASEFUND' || activities.type == 'ESCROWREFUNDED' || activities.type == 'WITHDRAWALOFFER' || activities.type == 'SUBMITPROPOSAL'">
                                                    <hr v-else-if="checkReview == 2 && (activities.type=='CONTRACTENDEDBYFREELANCER' || activities.type=='CONTRACTENDEDBYCLIENT' || activities.type=='CONTRACTENDEDBYAGENCY')">
                                                </b-row>
                                            </span>
                                            
                                            <b-button class="btn btn-outline-theme mt-4" @click="showHideRecentActivity = !showHideRecentActivity">{{(!showHideRecentActivity)?'Show More Activity':'Show Less Activity'}}</b-button>
                                        </b-card>
                                    </b-card-group>
                                </b-col>
                                <b-col lg="3" xl="3" sm="12">
                                    <b-card-group deck v-if="($store.state.accountType == 'agency' || $store.state.accountType == 'freelancer') && currentPage == 2">
                                        <b-card class="py-4 px-3 bg-white br-20 mt-2 about-talent-wrapper">
                                            <h4 class="fz-20 fw-600 text-black mr-2 mb-3">About the client</h4>
                                            <div class="star-rating mb-3">
                                                <label for="5-stars" class="star" :class="(contractDetails.clientrating.rating >= 1)?'active':''"><i class="fa fa-star"></i></label>
                                                <label for="4-stars" class="star" :class="(contractDetails.clientrating.rating >= 2)?'active':''"><i class="fa fa-star"></i></label>
                                                <label for="3-stars" class="star" :class="(contractDetails.clientrating.rating >= 3)?'active':''"><i class="fa fa-star"></i></label>
                                                <label for="2-stars" class="star" :class="(contractDetails.clientrating.rating >= 4)?'active':''"><i class="fa fa-star"></i></label>
                                                <label for="1-star" class="star" :class="(contractDetails.clientrating.rating == 5)?'active':''"><i class="fa fa-star"></i></label>
                                            </div>
                                            <h5 class="fz-18 fw-600">{{contractDetails.userDetails.country_name}}</h5>
                                            <!-- <p class="r-about fz-16">Suzhou 2:47 am</p> -->

                                            <h5 class="fz-18 fw-600">{{contractDetails.userDetails.postedJob}} Jobs Posted</h5>
                                            <p class="r-about fz-16">{{contractDetails.userDetails.hireRate}}% Hire Rate, {{contractDetails.userDetails.openJob}} Open Jobs</p>

                                            <h5 class="fz-18 fw-600">${{changeAmountvalue(contractDetails.userDetails.totalSpent)}} Total Spent</h5>
                                            <p class="r-about fz-16">{{contractDetails.userDetails.hired}} {{(contractDetails.userDetails.hired > 0)?"Hire's":'Hire'}}</p>

                                            <p class="r-about fz-16">Member Since {{contractDetails.userDetails.member_since}} </p>
                                        </b-card>
                                    </b-card-group>
                                    <b-card-group deck v-if="contractDetails.proposalDetails.agencyData != '' && contractDetails.proposalDetails.agencyData != null && $store.state.accountType == 'freelancer'">
                                        <b-card class="py-4 px-3 bg-white br-20 mt-2 about-talent-wrapper">
                                            <h4 class="fz-20 fw-600 text-black mr-2 mb-3">Agency</h4>
                                            <div class="d-md-flex align-items-normal text-left mb-4">
                                                <div class="img-wrapper mr-3 w-70 h-70 br-10">
                                                    <img :src="contractDetails.proposalDetails.agencyData.profile_picture_path"
                                                        class="img-fluid br-10">
                                                </div>
                                                <div class="ml-2 ml-sm-0">
                                                    <h4 class="fz-18 fw-600 text-black mr-2 mb-0 cursor-pointer" @click="$router.push({name:'agencyPublicProfile',path:'agency-public-profile',params:{uid:'ASS'+encodedid(contractDetails.proposalDetails.agencyData.login_master_id)}}).catch(()=>{})"> {{ shortName(contractDetails.proposalDetails.agencyData.first_name,contractDetails.proposalDetails.agencyData.last_name)}}</h4>
                                                </div>
                                            </div>
                                        </b-card>
                                    </b-card-group>
                                    <b-card-group deck v-else-if="$store.state.accountType == 'agency' && contractDetails.proposalDetails.freelancerData != '' && currentPage == 2">
                                        <b-card class="py-4 px-3 bg-white br-20 mt-2 about-talent-wrapper">
                                            <h4 class="fz-20 fw-600 text-black mr-2 mb-3">About the Talent</h4>
                                            <div class="d-md-flex align-items-normal text-left mb-4">
                                            <div class="img-wrapper mr-3 w-70 h-70">
                                                <img :src="contractDetails.proposalDetails.freelancerData.profile_picture_path" v-if="contractDetails.proposalDetails.freelancerData.profile_picture_path !=''"  class="img-fluid">
                                            </div>
                                            <div class="content ml-2">
                                                <h4 class="fz-18 fw-600 text-black mr-2 mb-0">{{shortName(contractDetails.proposalDetails.freelancerData.first_name,contractDetails.proposalDetails.freelancerData.last_name)}}</h4>
                                                <p class="fz-16 fw-500 text-muted mb-0 white-space-pre-line" v-if="contractDetails.proposalDetails.freelancerData.tagline != '' && contractDetails.proposalDetails.freelancerData.tagline != null">
                                                    <read-more more-str="Read more" :text="getHtmlContent(contractDetails.proposalDetails.freelancerData.tagline)" link="#" less-str="Read less" :max-chars="150"></read-more>
                                                </p>
                                            </div>
                                            </div>
                                            <h5 class="fz-16 text-black fw-600">{{contractDetails.proposalDetails.freelancerData.country_name}}</h5>
                                            <h5 class="fz-16 text-muted fw-500">{{contractDetails.proposalDetails.freelancerData.city_name}}</h5>
                                            <h5 class="fz-16 text-black fw-600">{{contractDetails.jobHistory.length}} jobs</h5>
                                            <h5 class="fz-16 text-black fw-600">${{contractDetails.proposalDetails.freelancerData.totalEarn}} total earned</h5>
                                        </b-card>
                                    </b-card-group>
                                    <b-card-group deck v-else-if="$store.state.accountType == 'freelancer' && (contractDetails.proposalDetails.agencyData == '' || contractDetails.proposalDetails.agencyData == null) && currentPage == 2">
                                        <b-card class="py-4 px-3 bg-white br-20 mt-2 about-talent-wrapper">
                                            <h4 class="fz-20 fw-600 text-black mr-2 mb-3">About the Talent</h4>
                                            <div class="d-md-flex align-items-normal text-left mb-4">
                                            <div class="img-wrapper mr-3 w-70 h-70">
                                                <img :src="contractDetails.proposalDetails.freelancerData.profile_picture_path" v-if="contractDetails.proposalDetails.freelancerData.profile_picture_path !=''"  class="img-fluid">
                                            </div>
                                            <div class="content ml-2">
                                                <h4 class="fz-18 fw-600 text-black mr-2 mb-0">{{shortName(contractDetails.proposalDetails.freelancerData.first_name,contractDetails.proposalDetails.freelancerData.last_name)}}</h4>
                                                <p class="fz-16 fw-500 text-muted mb-0 white-space-pre-line" v-if="contractDetails.proposalDetails.freelancerData.tagline != '' && contractDetails.proposalDetails.freelancerData.tagline != null">
                                                    <read-more more-str="Read more" :text="getHtmlContent(contractDetails.proposalDetails.freelancerData.tagline)" link="#" less-str="Read less" :max-chars="150"></read-more>
                                                </p>
                                            </div>
                                            </div>
                                            <h5 class="fz-16 text-black fw-600">{{contractDetails.proposalDetails.freelancerData.country_name}}</h5>
                                            <h5 class="fz-16 text-muted fw-500">{{contractDetails.proposalDetails.freelancerData.city_name}}</h5>
                                            <h5 class="fz-16 text-black fw-600">{{contractDetails.jobHistory.length}} jobs</h5>
                                            <h5 class="fz-16 text-black fw-600">${{contractDetails.proposalDetails.freelancerData.totalEarn}} total earned</h5>
                                        </b-card>
                                    </b-card-group>
                                        <b-card-group deck>
                                    <b-card class="py-4 px-3 bg-white br-20 mt-2">
                                        <h4 class="fz-20 fw-600 text-black mr-2 mb-3">Your Feedback to {{contractDetails.userDetails.first_name}}</h4>
                                        <template v-if="contractDetails.checkfeedbackgiven.length == 2">
                                            <span v-for="(feedBack,index) in contractDetails.checkfeedbackgiven" :key="index">
                                                <template v-if="feedBack.user_type == 'freelancer' || feedBack.user_type == 'agency'">
                                                    <div class="star-rating mt-2 mb-2">
                                                        <label for="5-stars" class="star "
                                                            :class="(feedBack.rating >= 1) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                        <label for="4-stars" class="star "
                                                            :class="(feedBack.rating >= 2) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                        <label for="3-stars" class="star"
                                                            :class="(feedBack.rating >= 3) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                        <label for="2-stars" class="star"
                                                            :class="(feedBack.rating >= 4) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                        <label for="1-star" class="star"
                                                            :class="(feedBack.rating == 5) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                    </div>
                                                    <p class="fz-16 fw-500 text-black mb-0">{{ feedBack.review }}.
                                                    </p>
                                                </template>
                                            </span>
                                        </template>
                                        <span v-else>
                                            <p class="fz-16 fw-500 text-black mb-0">No Feedback Found.</p>
                                        </span>
                                        <h4 class="fz-20 fw-600 text-black mr-2 mt-3 mb-3">{{contractDetails.userDetails.first_name}}'s Feedback to You</h4>

                                        <template v-if="contractDetails.checkfeedbackgiven.length == 2">
                                            <span v-for="(feedBack,index) in contractDetails.checkfeedbackgiven" :key="index">
                                                <template v-if="feedBack.user_type == 'client'">
                                                    <div class="star-rating mt-2 mb-2">
                                                        <label for="5-stars" class="star "
                                                            :class="(feedBack.rating >= 1) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                        <label for="4-stars" class="star "
                                                            :class="(feedBack.rating >= 2) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                        <label for="3-stars" class="star"
                                                            :class="(feedBack.rating >= 3) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                        <label for="2-stars" class="star"
                                                            :class="(feedBack.rating >= 4) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                        <label for="1-star" class="star"
                                                            :class="(feedBack.rating == 5) ? 'text-theme' : ''">
                                                            <i class="fa fa-star"></i>
                                                        </label>
                                                    </div>
                                                    <p class="fz-16 fw-500 text-black mb-0">{{ feedBack.review }}.
                                                    </p>
                                                </template>
                                            </span>
                                        </template>
                                        <span v-else>
                                            <p class="fz-16 fw-500 text-black mb-0">No Feedback Found.</p>
                                        </span>
                                    </b-card>
                                    </b-card-group>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card-text>
    </b-container>
</template>
<script>
import { mapActions, mapGetters }   from "vuex";
import Recentfiles      from "@/components/contract/Recentfiles.vue";
import chatBoxData      from '@/components/chat/chatBoxData.vue'
import SubmitWork       from '../../components/modal/SubmitWork.vue'
import ReviewWorkVue    from "../../components/modal/ReviewWork.vue";
import ManageMilestone  from "../../components/modal/ManageMilestone.vue";
export default {

 components: {
    Recentfiles,
    chatBoxData,
    SubmitWork,
    ReviewWorkVue,
    ManageMilestone
 },
 data(){
    return {
        job_id              : '',
        user_id             : '',
        freelancer_id       : '',
        contractDetails     : {
            userDetails         : '',
            jobDetails          : '',
            proposalDetails     : {
                agencyData      : '',
                freelancerData  : '',
            },
            jobHistory          : [],
            MilestoneDetails    : [],
            checkfeedbackgiven  : [],
            recentActivties     : [],
            recentFiles         : [],
            clientrating        : '',
        },
        review              : {
            clientfeedBack      : '',
            freelancerfeedBack  : '',
        },
        summeryDetails      : false,
        feedbackRequest     : '',
        currentPage         : 0,
        UploadedFileName    : '',
        submitWorkPayment   : {
            job_id              : '',
            milestone_id        : '',
            pay                 : 0,
            message             : '',
            attachment          : [],
            description         : '',
            dueDate             : '',
            amount              : '',
            other_amount        : 0,
        },
        showHideRecentActivity  : false,
        chatroomdata        : '',
        ContractEnded       : false,
        reviewWork          : {
            submittedWork       : [],
        },
        sidebarData         : [],
        messageSectionShow  : false,
        chatroomId          : '',
        chatroomMembers     : [],
        showSubmitworkModal : false,
    }
 },
 computed:{
    ...mapGetters({roomlist : "global/getRoomData",chatroomResponse : "global/getchatroomResponse"}),

    sidebarDetails: {
        get() {
            return this.sidebarData;
        },
        set() {
            return this.sidebarData=[];
        },
    },
    
    totalamount() {
        var amount = 0;
        if(this.contractDetails.MilestoneDetails.length > 0){
            this.contractDetails.MilestoneDetails.map(data => {
                amount += parseFloat(data.amount);
            });
        }
        return amount.toFixed(2);
    },
    // this fun is for to calculate escrow amount
    escrowAmount() {
        var amount = parseFloat(0);
        if (this.contractDetails.MilestoneDetails.length > 0) {
            this.contractDetails.MilestoneDetails.map(data => {
                if (data.paymentStatus == 1 && data.milestone_status == 0) {
                    amount += parseFloat(data.amount);
                }else if(data.paymentStatus == 1 && data.milestone_status == 1){
                    if(parseFloat(data.amount) > parseFloat(data.release_amount) && parseFloat(data.refund_amount) == 0){
                        amount += (parseFloat(data.amount) - parseFloat(data.release_amount))
                    }
                }
            });
        }
        return amount.toFixed(2);
    },

    // this fun is for to calculate escrow refunded amount
    refundedescrowAmount(){
        var amount = parseFloat(0);
        if (this.contractDetails.MilestoneDetails.length > 0 && this.contractDetails.proposalDetails.application_status == 6) {
            this.contractDetails.MilestoneDetails.map(data => {
                if (data.paymentStatus == 1) {
                    amount += parseFloat(data.refund_amount);
                }
            });
        }
        return amount.toFixed(2);
    },
    // this fun is for to calculate the released amount
    ReleaseFund() {
        var amount = 0;
        if (this.contractDetails.MilestoneDetails.length > 0) {
            this.contractDetails.MilestoneDetails.map(data => {
                if (data.paymentStatus == 1 && data.milestone_status == 1) {
                    amount += parseFloat(data.release_amount);
                }
            });
        }
        return amount.toFixed(2);
    },
    // this fun is for to check both user and client give review
    checkReview(){
        var count = 0;
        this.contractDetails.recentActivties.map((data)=>{
            if(data.type=='CONTRACTENDEDBYFREELANCER' || data.type=='CONTRACTENDEDBYCLIENT' || data.type=='CONTRACTENDEDBYAGENCY'){
                count++;
            }
        })
        return count;
    },
    // this fun is for to check current user give review
    checkcurrentuserReview(){
        var status = 0;
        if(this.$store.state.accountType == 'freelancer' && this.contractDetails.proposalDetails.agency_id != 0){
            return true;
        }
        if(this.$store.state.accountType == 'freelancer' && this.contractDetails.proposalDetails.agency_id == 0){
            this.contractDetails.checkfeedbackgiven.map((data)=>{
                if(data.user_type == this.$store.state.accountType && this.$store.state.accountType == 'freelancer'){
                    status++;
                }
            })
        }
        if(this.$store.state.accountType == 'agency'){
            this.contractDetails.checkfeedbackgiven.map((data)=>{
                if(data.user_type == this.$store.state.accountType && this.$store.state.accountType == 'agency'){
                    status++;
                }
            })
        }
        return (status == 0) ? false : true;
    },
    checkClientReview(){
        var review =  this.contractDetails.checkfeedbackgiven.filter(data => {return (data.from_id == this.contractDetails.jobDetails.client_id)?data.rating:0;})
        return review[0]['rating'];
    }
 },
 mounted(){
    window.scrollTo(0,0);
    if(this.$route.params.pid!="" && this.$route.params.uid !=""){
        this.job_id         = this.$route.params.pid;
        this.freelancer_id  = this.decodeId(this.$route.params.uid.slice(1));
    }else{
        this.$router.push({'name':this.$store.state.accountType+'allContracts','path':this.$store.state.accountType+'/all-contracts'}).catch(()=>{});
    }
    this.currentPage = this.$route.query.current;
    // this fun is for to get contractDetails
    this.getContractdetails();
    // if(currentPage == 'message'){
    //     setTimeout(() => {
    //         this.currentPage = 1;
    //     }, 100);
    // }
 },
 methods:{
    ...mapActions(['genericAPIPOSTRequest','UpdateCurrentUrl']),
    ...mapActions("global",["EmptyMessageData","UpdateMessageRequestData"]),

    // this fun is for to get invoicedata
    async getinvoiceData(_d){
          var res = await this.genericAPIPOSTRequest({
              requestUrl: "freelancertransactionHistory",
              params    : {
                "data"    : this.getLoginUserIdRequest(),
                "search"  : {
                    sort                    : 'ASC',
                    transactionCategory     : 'invoice',
                    milestone_id            : _d.job_milestone_id,
                    client_id               : '',
                    freelancer_id           : '',
                    type                    : this.$store.state.accountType,
                },
              },
          });  
          if(res!=""){
            res = res.response[0];
            if(res.type == "Invoice"){
                if(res.client_id !='' || res.client_id != null){
                var clientData = await this.genericAPIPOSTRequest({
                    requestUrl: "user/getUserDetails",
                    params: { 
                        'user_id'   : 'ASS'+this.encodedid(res.client_id),
                        'usertype'  : "client"
                    },
                });
                if (clientData) {
                    res.details.frombilling = clientData;
                }
                }
            }
            this.$store.dispatch("updateSingleInvoiceData", res);
            window.open(this.$store.state.frontendUrl+'singleInvoice.html');
          }
        },
    // this fun is for to join chat room
    async joinRoom(){
        var res = await this.genericAPIPOSTRequest({
            requestUrl      : "addChatInvitation",
            params          : {
                'chat_room_id'          : this.chatroomId,
                'member_permission_id'  : this.$store.state.currentUserData.permission.members_permission_id,
                'user_id'               : this.$store.state.currentUserData.permission.user_id,
                'type'                  : 1,
                'permission'            : 1,
                'created_by'            : this.$store.state.currentUserData.login_master_id
            }
        });
        if(res){
            this.$toastr.s("Joined chat Room");
            this.getChatlist();
        }else{
            this.$toastr.e("Some error Found Try again later");
        }
    },
    // this fun is for to change current page url
    changeCurrentPage(_v){
        var new_url = "?current="+_v;
        window.history.pushState('', '', new_url);
        // if(_v == 1){
            // setTimeout(() => {
            //     this.getChatlist();
            // }, 1000);
        // }
    },
    // this fun is for to check any new milestone request pending
    checkMilestoneIspending(){
        var status=false;
        this.contractDetails.MilestoneDetails.map((data)=>{
            if(data.active == 0){
                status = true;
            }
        })
        return status
    },
    // this fun is for to open manage milestone sidebar;
    manageMilestoneData(){
        this.sidebarData = this.contractDetails.MilestoneDetails;
        setTimeout(() => {
            this.$refs.manageMilestoneFun.assginSidebarData();    
        }, 1000);
    },
    // this fun id for to refresh milestone 
    async updatedMilestone(){
        var getMilestoneData = await this.genericAPIPOSTRequest({
            requestUrl  : "getCreatedMilestone",
            params      : {"user_id" : this.contractDetails.proposalDetails.user_id,"job_id" : this.contractDetails.jobDetails.jobs_id},
        });
        if (getMilestoneData.proposal.application_status == 6) {
            this.ContractEnded = true;
            this.contractDetails.MilestoneDetails = getMilestoneData.milestone;
            this.contractDetails.proposalDetails  = getMilestoneData.proposal;
        } else {
            this.contractDetails.MilestoneDetails = getMilestoneData.milestone;
            this.contractDetails.proposalDetails  = getMilestoneData.proposal;
        }
        this.getRecentfileData();
        this.getRecentActivities();
    },
    //this fun is for to get status label 
    StatusLabel(_v){
        if(_v.milestone_status == 1){
            return 'Released';
        }else if(_v.active == 0){
            return 'Approve Pending';
        }else if(_v.paymentStatus == 0 && _v.active == 1){
            return 'Pending';
        }else if(_v.paymentStatus == 1 && _v.submittedWork.length == 0){
            return 'Funded';
        }else if(_v.submittedWork.length > 0){
            var index   = _v.submittedWork.length-1;
            var status  = _v.submittedWork[index].status;
            if(status == 1 || status == 3){
                return 'Active';
            }
        }
    },
    // this fun is for to check submitted work status
    checkWorkstatus(_v){
        if(_v.submittedWork.length > 0){
            var index = _v.submittedWork.length-1;
            return _v.submittedWork[index].status;
        }else{
            return null;
        }
    },
    // this fun is for to get chat user list
    async getChatlist(){
        this.chatroomdata   = [];
        var  param          = {};
        var that            = this;
        var url             = '';
        if(that.$store.state.accountType == 'freelancer'){
            url     = 'getchatroom';
            param   = {'user_id':this.contractDetails.proposalDetails.user_id,'client_id':this.contractDetails.jobDetails.client_id, 'job_id': this.contractDetails.jobDetails.jobs_id,'userType': this.$store.state.accountType,'status':1};
        }else if(this.$store.state.accountType == 'agency' && this.$store.state.currentUserData.login_master_id == this.$store.state.listAccount.company.login_master_id){
            url     = 'getchatroom';
            param   = {'user_id':this.contractDetails.proposalDetails.user_id,'agency_id':this.contractDetails.proposalDetails.agency_id,'client_id':this.contractDetails.jobDetails.client_id, 'job_id': this.contractDetails.jobDetails.jobs_id,'userType': this.$store.state.accountType};
        }else if(this.$store.state.accountType == 'agency' && this.$store.state.currentUserData.login_master_id != this.$store.state.listAccount.company.login_master_id){
            var chatroomId = await this.genericAPIPOSTRequest({
                requestUrl  : 'getChatroomId',
                params      : {'user_id':this.contractDetails.proposalDetails.user_id,'agency_id':this.contractDetails.proposalDetails.agency_id,'client_id':this.contractDetails.jobDetails.client_id,'job_id': this.contractDetails.jobDetails.jobs_id},
            });
            if(chatroomId!=''){
                this.chatroomId = chatroomId;
            }
            url     = 'getChatInvitation';
            param   = {'user_id':this.$store.state.currentUserData.permission.user_id,'member_permission_id': this.$store.state.currentUserData.permission.members_permission_id,'chat_room_id': this.chatroomId};
        }
        var res = await this.genericAPIPOSTRequest({
            requestUrl  : url,
            params      : param,
        });
        if (res.length > 0) {
            this.messageSectionShow     = true;
            this.chatroomdata           = res[0]; 
            setTimeout(() => {
                this.$refs.oprateChatbox.getselectedusermessage();
                this.EmptyMessageData({params : []});
                this.UpdateMessageRequestData({params:{
                    chat_room_id        : this.chatroomdata.chat_room_id,
                    start_limit         : 0,
                    end_limit           : 10,
                    cycle               : 'first',
                    lastId              : '',
                    firstId             : '',
                }});
            }, 1000);
        }else{
            this.messageSectionShow = false;
            this.getchatroomMembers(this.chatroomId);
        }
    },
    // this fun is for to get chat room members
    async getchatroomMembers(_i){
        this.chatroomMembers = [];
        var res = await this.genericAPIPOSTRequest({
            requestUrl:'chatRoomMembers',
            params: {
                'chat_room_id'  : _i,
                'start_limit'   : 0
            },
        });
        if (res.length > 0 ) {
            this.chatroomMembers = res;
        }
    },
    // this fun is for to =remove attachment
    removeAttachment(_i){
        this.userJobApply.attachment.splice(_i,1);
    },
    // this fun is for to set the value in submit work model
    submitWorkmodal(_v){
        var that = this;
        this.submitWorkPayment.job_id           = that.contractDetails.proposalDetails.jobs_id;
        that.submitWorkPayment.milestone_id     = _v.job_milestone_id;
        that.submitWorkPayment.pay              = _v.amount;
        that.submitWorkPayment.amount           = _v.amount;
        that.submitWorkPayment.other_amount     = _v.amount;
        that.submitWorkPayment.description      = _v.description;
        that.submitWorkPayment.dueDate          = _v.dueDates;
        that.showSubmitworkModal                = true;
        setTimeout(()=>{
            that.$bvModal.show('submitWork-modal');
        }, 500)
        
    },
    //this fun is for to get feedback request
    async requestForfeedback(){
        var feedback = await this.genericAPIPOSTRequest({
            requestUrl  : "requestFeedback",
            params      : {'job_id' : this.contractDetails.jobDetails.jobs_id, 'to_id' : this.contractDetails.jobDetails.client_id},
        });
        if(feedback){
            this.$toastr.s('Request submitted Successfull');
        }
    },
    // this fun is for to get contractDetails
    async getContractdetails(){
        var GetJobDetails = await this.genericAPIPOSTRequest({
            requestUrl  : "getJobDetails_new",
            params      : {
                'poid'          : this.job_id,
                'freelancer_id' : this.freelancer_id,
            },
        });
        if(GetJobDetails){
            this.contractDetails.jobDetails = GetJobDetails[0];
        }
        var userDetails = await this.genericAPIPOSTRequest({
            requestUrl  : "user/getUserDetails",
            params      : {
                'user_id' : 'ASS'+this.encodedid(this.contractDetails.jobDetails.client_id),
                'usertype' : 'client',
            },
        });
        if(userDetails){
          this.contractDetails.userDetails = userDetails;
        }
        var proposalDetails = await this.genericAPIPOSTRequest({
            requestUrl  : "getProposalDetail",
            params      : {"job_id":this.contractDetails.jobDetails.jobs_id, "type":this.$store.state.accountType},
        });
        if(proposalDetails){
          this.contractDetails.proposalDetails = proposalDetails;
          this.getRecentfileData();
        }
        var getMilestoneData = await this.genericAPIPOSTRequest({
            requestUrl  : "getCreatedMilestone",
            params      : {"user_id":this.contractDetails.proposalDetails.user_id,"job_id":this.contractDetails.jobDetails.jobs_id},
        });
        this.getChatlist();
        this.getRecentActivities();
        if (getMilestoneData.proposal.application_status == 6) {
            this.ContractEnded = true;
            this.contractDetails.MilestoneDetails = getMilestoneData.milestone;
            this.contractDetails.proposalDetails  = getMilestoneData.proposal;
        } else{
            this.contractDetails.MilestoneDetails = getMilestoneData.milestone;
            this.contractDetails.proposalDetails  = getMilestoneData.proposal;
        }
        var jobHistory = await this.genericAPIPOSTRequest({
            requestUrl  : "getJobdataList",
            params      : {"filter":6},
        });
        if(jobHistory){
          this.contractDetails.jobHistory = jobHistory;  
        }

        var clientReview = await this.genericAPIPOSTRequest({
            requestUrl: "getUserReviewCal",
            params: {
                "user_id": this.contractDetails.jobDetails.user_id
            },
        });
        if(clientReview){
            this.contractDetails.clientrating =  clientReview;
        }

        var feedback = await this.genericAPIPOSTRequest({
            requestUrl  : "checkfeedbackRequest",
            params      : {"from_id":this.contractDetails.jobDetails.client_id,"job_id":this.contractDetails.jobDetails.jobs_id},
        });
        if(feedback){
          this.feedbackRequest = feedback;  
        }

        var checkfeedback = await this.genericAPIPOSTRequest({
            requestUrl  : "checkfeedbackGiven",
            params      : {
                'job_id'    : this.contractDetails.jobDetails.jobs_id,
                'job_application_id' :this.contractDetails.proposalDetails.job_application_id
            },
        });
        if(checkfeedback){
            this.contractDetails.checkfeedbackgiven = checkfeedback;
        }
    },
    // this fun is for to get recentfile data
    async getRecentfileData(){
        var res = await this.genericAPIPOSTRequest({
            requestUrl  : "getRecentFiles",
            params      : { "job_application_id": this.contractDetails.proposalDetails.job_application_id},
        });
        if(res){
            this.contractDetails.recentFiles = res;
        }else{
            this.contractDetails.recentFiles = [];
        }
    },
    // this fun is for to get all recent activities
    async getRecentActivities(){
        var recentActivties = await this.genericAPIPOSTRequest({
            requestUrl  : "getContractactivities",
            params      : {"job_id":this.contractDetails.jobDetails.jobs_id,"job_application":this.contractDetails.proposalDetails.job_application_id},
        });
        if(recentActivties.length > 0){
            this.contractDetails.recentActivties = recentActivties;
        }
    },
},
}
</script>